/**
 * Compresses an image file based on specified parameters
 * @param {File} file - The original image file
 * @param {Object} options - Compression options
 * @param {number} options.maxWidth - Maximum width of the compressed image
 * @param {number} options.maxHeight - Maximum height of the compressed image
 * @param {number} options.quality - Compression quality (0 to 1)
 * @returns {Promise<{file: File, previewUrl: string}>} - Compressed file and preview URL
 */
export const compressImage = async (
	file,
	{ maxWidth = 1920, maxHeight = 1080, quality = 0.7 } = {}
) => {
	// Log original file size
	console.log(
		"Original File Size:",
		(file.size / 1024 / 1024).toFixed(2) + " MB"
	);

	return new Promise((resolve, reject) => {
		const image = new Image();
		const reader = new FileReader();

		reader.onload = (e) => {
			image.src = e.target.result;
			image.onload = () => {
				// Create canvas for compression
				const canvas = document.createElement("canvas");
				let width = image.width;
				let height = image.height;

				// Calculate new dimensions while maintaining aspect ratio
				if (width > height) {
					if (width > maxWidth) {
						height *= maxWidth / width;
						width = maxWidth;
					}
				} else {
					if (height > maxHeight) {
						width *= maxHeight / height;
						height = maxHeight;
					}
				}

				canvas.width = width;
				canvas.height = height;

				// Draw image on canvas
				const ctx = canvas.getContext("2d");
				ctx.drawImage(image, 0, 0, width, height);

				// Convert to blob
				canvas.toBlob(
					(blob) => {
						// Create new file from blob
						const compressedFile = new File([blob], file.name, {
							type: "image/jpeg",
							lastModified: Date.now(),
						});

						// Log compressed file size
						console.log(
							"Compressed File Size:",
							(compressedFile.size / 1024 / 1024).toFixed(2) + " MB"
						);
						console.log(
							"Compression ratio:",
							((1 - compressedFile.size / file.size) * 100).toFixed(2) + "%"
						);

						// Create preview URL
						const previewUrl = URL.createObjectURL(compressedFile);

						resolve({ file: compressedFile, previewUrl });
					},
					"image/jpeg",
					quality
				);
			};

			image.onerror = (error) => {
				reject(new Error("Error loading image"));
			};
		};

		reader.onerror = (error) => {
			reject(new Error("Error reading file"));
		};

		reader.readAsDataURL(file);
	});
};
