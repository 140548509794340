import { useCallback, useEffect, useState, lazy } from "react";
import Header from "component/Header/CommonHeader";
import Footer from "component/Footer";
import EnterOTPExisting from "pages/auth/EnterOTPExisting/index";
import { Button, TextField, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { generateOtp } from "./auth/api";
import { getCurrentLanguage, translations } from "utils/translations";
import { Link } from "react-router-dom";
const RaiseNewDemand = lazy(() => import("component/RaiseNewDemand"));

function PublicRaiseDemandWork() {
	const currentLanguage = getCurrentLanguage();
	const t = translations[currentLanguage];

	const [showPhoneVerification, setShowPhoneVerification] = useState(true);
	const [phoneNumber, setPhoneNumber] = useState(""); // State for phone number
	const [isOtpSent, setIsOtpSent] = useState(false); // State to track OTP sent status
	const [isSubmitting, setIsSubmitting] = useState(false);

	const isAuthenticated = useCallback(() => {
		return !!Cookies.get("user_name");
	}, []);

	useEffect(() => {
		setShowPhoneVerification(!isAuthenticated());
	}, [isAuthenticated]);

	const handlePhoneSubmit = async (e) => {
		e.preventDefault();
		setIsSubmitting(true); // Disable the button
		try {
			await handleVerifyPhoneNumber();
		} catch (error) {
			setIsSubmitting(false); // Re-enable the button if there's an error
		}
	};

	const handleVerifyPhoneNumber = async () => {
		const result = await generateOtp(phoneNumber, "true"); // Call generateOtp
		if (result && result.status) {
			setIsOtpSent(true);
			// setOtp(result.data.otp_value);
		} else {
			setIsSubmitting(false); // Re-enable the button if OTP sending fails
		}
	};

	const handleBackToRaiseNewDemandClick = () => {
		if (!isAuthenticated()) {
			setShowPhoneVerification(true);
			setPhoneNumber("");
			setIsOtpSent(false);
		}
	};

	const handleNewAccountDemand = () => {
		Cookies.remove("user_name");
		setShowPhoneVerification(true);
		setPhoneNumber("");
		setIsOtpSent(false);
		setIsSubmitting(false);
	};

	return (
		<div className="flex flex-col min-h-screen">
			<Header />
			<main className="flex-grow container mx-auto p-4">
				{/* {showPhoneVerification ? (
					<div className="w-full flex items-center justify-center bg-gray-100 py-8">
						<div className="w-full max-w-md p-6 bg-white rounded-lg shadow-md">
							<Typography
								variant="h5"
								component="div"
								className="font-bold mb-2"
							>
								{t.public_verify_phone_number}
							</Typography>
							<form
								onSubmit={handlePhoneSubmit}
								onKeyDown={(e) =>
									isSubmitting && e.key === "Enter" && e.preventDefault()
								}
								className="space-y-4"
							>
								<TextField
									label={t.phone_number}
									value={phoneNumber}
									onChange={(e) => setPhoneNumber(e.target.value)}
									fullWidth
									margin="normal"
									required
									type="tel"
								/>
								<Button
									type="submit"
									variant="contained"
									color="primary"
									fullWidth
									disabled={isSubmitting} // Disable the button if submitting
								>
									{t.send_otp}
								</Button>
							</form>
							{isOtpSent && (
								<div className="mt-6 space-y-4">
									<EnterOTPExisting
										phoneNumber={phoneNumber}
										onSuccess={(result) => {
											Cookies.set("user_name", result.user_name);
											setShowPhoneVerification(false);
										}}
										newUserFlag="true"
									/>
								</div>
							)}
						</div>
					</div>
				) : (
					<RaiseNewDemand
						isPublic={true}
						backToRaiseNewDemandClicked={handleBackToRaiseNewDemandClick}
						requestedNewAccountDemand={handleNewAccountDemand}
					/>
				)} */}

				{/* temporarily deactivate this page sta */}
				<div className="flex items-center justify-center pt-10">
					<p className="text-2xl font-bold">
						ಮಹಾತ್ಮಗಾಂಧಿ ನರೇಗಾ ಯೋಜನೆಯಡಿ ಆನ್ ಲೈನ್ ಮೂಲಕ ವೈಯಕ್ತಿಕ ಕಾಮಗಾರಿ ಬೇಡಿಕೆ
						ಸಲ್ಲಿಸುವ ಅವಧಿ ಮುಕ್ತಾಯಗೊಂಡಿರುತ್ತದೆ. ಹೆಚ್ಚಿನ ಮಾಹಿತಿಗಾಗಿ ಹತ್ತಿರದ
						ಗ್ರಾಮಪಂಚಾಯಿತಿಯನ್ನು ಸಂಪರ್ಕಿಸಿ.
					</p>
				</div>
				{/* also show a link to the main page */}
				<div className="flex items-center justify-start pt-5">
					<Link
						to="/"
						className="text-blue-500 btn btn-primary text-lg text-underline"
					>
						ಹಿಂತಿರುಗಿ
					</Link>
				</div>
				{/* end of temporarily deactivate this page */}
			</main>
			<Footer />
		</div>
	);
}

export default PublicRaiseDemandWork;
