import React, { useState, useEffect, useMemo } from "react";
import {
	TextField,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Typography,
	Button,
	Grid,
	Paper,
	Snackbar,
	InputAdornment,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import { completePreMeasurement } from "../api";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ArrowBack from "@mui/icons-material/ArrowBack";
import JobCardSelector from "./PreMeasurement/JobCardSelector";
import WorkFilters from "component/WorkFilters";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { compressImage } from "utils/imageCompression";
import PhotoUpload from "./PhotoUpload";
import LocationAccess from "./LocationAccess";

if (typeof window !== "undefined") {
	// Safely remove or initialize ethereum property if it's causing issues
	if (!window.ethereum) {
		window.ethereum = {};
	}
}

const PreMeasurementForm = ({ data, onReject, isReadOnly }) => {
	const [formData, setFormData] = useState({});
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const navigate = useNavigate();
	const location = useLocation();
	const [amountError, setAmountError] = useState("");
	const [uploadedPhoto, setUploadedPhoto] = useState(null); // Add state for uploaded photo
	const [fileError, setFileError] = useState(""); // State for file error message
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [uploadedPhotoUrl, setUploadedPhotoUrl] = useState(null);
	const [selectedJobCard, setSelectedJobCard] = useState(null);

	const [workFilters, setWorkFilters] = useState({
		category: data.results.work_sc1_id || "",
		subType1: data.results.work_sc2_id || "",
		subType2: data.results.work_sc3_id || "",
		subType3: data.results.work_sc4_id || "",
		subType4: data.results.work_sc5_id || "",
	});
	const [locationError, setLocationError] = useState("");

	const queryParams = new URLSearchParams(location.search);
	const isPartialSubmit = queryParams.get("partialSubmit") === "1";

	const memoizedWorkFilters = useMemo(
		() => ({
			category: data.results.work_sc1_id || "",
			subType1: data.results.work_sc2_id || "",
			subType2: data.results.work_sc3_id || "",
			subType3: data.results.work_sc4_id || "",
			subType4: data.results.work_sc5_id || "",
		}),
		[data.results]
	);

	const requestLocation = () => {
		if (!navigator?.geolocation) {
			const message = "Geolocation is not supported by this browser.";
			console.error(message);
			setLocationError(message);
			setSnackbarMessage(message);
			setSnackbarOpen(true);
			return;
		}

		navigator.geolocation.getCurrentPosition(
			(position) => {
				try {
					const { latitude, longitude } = position.coords;
					handleLocationChange(latitude, longitude);
					setLocationError("");
				} catch (error) {
					console.error("Error processing location data:", error);
					setLocationError("Error processing location data. Please try again.");
				}
			},
			(error) => {
				console.error("Error obtaining location: ", error);
				let message = "Please allow location access for better functionality.";
				setLocationError(message);
			},
			{
				enableHighAccuracy: true,
				timeout: 10000,
				maximumAge: 0,
			}
		);
	};

	useEffect(() => {
		// Reset form data when the location changes
		setFormData({});
		// Add any other reset logic here

		// Autofill form data if results.form_data is available
		if (data.results.form_data) {
			const { form_data } = data.results;

			// Autofill basic fields
			setFormData({
				variable_amount: form_data.estimated_amount || "",
				work_name: form_data.work_name || "",
				address: form_data.address || "",
				latitude: form_data.latitude || "",
				longitude: form_data.longitude || "",
			});

			// Autofill gQuestion fields
			if (form_data.gQuestion) {
				form_data.gQuestion.forEach((question) => {
					const uniqueId = question.uid;
					setFormData((prevData) => ({
						...prevData,
						[uniqueId]: question.selected_value || question.text_value || "",
						[`${uniqueId}_number`]: question.number || "",
						[`${uniqueId}_length`]: question.length || "",
						[`${uniqueId}_width`]: question.width || "",
						[`${uniqueId}_depth`]: question.depth || "",
					}));
				});
			}

			// Autofill iQuestion fields if they exist
			if (form_data.iQuestion) {
				form_data.iQuestion.forEach((question) => {
					const uniqueId = question.uid;
					setFormData((prevData) => ({
						...prevData,
						[uniqueId]: question.selected_value || question.text_value || "",
						[`${uniqueId}_number`]: question.number || "",
						[`${uniqueId}_length`]: question.length || "",
						[`${uniqueId}_width`]: question.width || "",
						[`${uniqueId}_depth`]: question.depth || "",
					}));
				});
			}

			// Autofill uploaded photo
			if (data.uploads && form_data.upload_file) {
				const photoUrl = `${data.uploads}${form_data.upload_file}`;
				setUploadedPhotoUrl(photoUrl);
				// Create a File object from the URL
				fetch(photoUrl)
					.then((res) => res.blob())
					.then((blob) => {
						const file = new File([blob], form_data.upload_file, {
							type: blob.type,
						});
						setUploadedPhoto(file);
					})
					.catch((error) =>
						console.error("Error fetching uploaded photo:", error)
					);
			}

			// Autofill JobCard
			if (form_data.job_card_id) {
				setFormData((prevData) => ({
					...prevData,
					job_card_id: form_data.job_card_id,
				}));
				setSelectedJobCard({
					job_card_id: form_data.job_card_id,
					job_card_number: form_data.job_card_number,
				});
			}
		}
	}, [data.results.form_data, data.uploads, isMobile, isReadOnly]);

	const handleLocationChange = (latitude, longitude) => {
		setFormData((prevData) => ({
			...prevData,
			latitude: latitude?.toString() || "",
			longitude: longitude?.toString() || "",
		}));
	};

	const handleInputChange = (uid, value) => {
		setFormData((prevData) => ({
			...prevData,
			[uid]: value,
		}));

		// Validate amount if it's a variable amount type
		if (uid === "variable_amount" && results.amount_type === "VARIABLE") {
			const maxEstimation = Number(results.max_estimation);
			if (Number(value) > maxEstimation) {
				setAmountError(`Amount cannot exceed ${maxEstimation}`);
			} else if (Number(value) < 10000) {
				setAmountError("Amount must be at least ₹10,000");
			} else {
				setAmountError("");
			}
		}
	};

	const handleJobCardSelect = (selectedJobCard) => {
		if (selectedJobCard) {
			setFormData((prevData) => ({
				...prevData,
				job_card_id: selectedJobCard.job_card_id,
			}));
			setSelectedJobCard(selectedJobCard);
		}
	};

	const renderField = (question, questionType) => {
		const uniqueId = question.uid;
		const isRequired = question.mandatory === "Y";

		switch (question.field_type) {
			case "number":
				return (
					<Grid container spacing={2}>
						<Grid item xs={12} md={question.unit_type ? 10 : 12}>
							<TextField
								type="number"
								fullWidth
								id={uniqueId}
								value={formData[uniqueId] || ""}
								onChange={(e) => handleInputChange(uniqueId, e.target.value)}
								required={isRequired}
								disabled={isReadOnly} // Disable if read-only
							/>
						</Grid>
						{question.unit_type && (
							<Grid item xs={12} md={2}>
								<Typography>{question.unit_type}</Typography>
							</Grid>
						)}
					</Grid>
				);
			case "LV":
				return (
					<Grid container spacing={2}>
						<Grid item xs={12} md={5}>
							<TextField
								type="number"
								label="Length"
								fullWidth
								id={`${uniqueId}_length`}
								value={formData[`${uniqueId}_length`] || ""}
								onChange={(e) =>
									handleInputChange(`${uniqueId}_length`, e.target.value)
								}
								disabled={isReadOnly} // Disable if read-only
							/>
						</Grid>
						<Grid item xs={12} md={5}>
							<TextField
								type="number"
								label="Width"
								fullWidth
								id={`${uniqueId}_width`}
								value={formData[`${uniqueId}_width`] || ""}
								onChange={(e) =>
									handleInputChange(`${uniqueId}_width`, e.target.value)
								}
								disabled={isReadOnly} // Disable if read-only
							/>
						</Grid>
						{question.unit_type && (
							<Grid item xs={12} md={2}>
								<Typography>{question.unit_type}</Typography>
							</Grid>
						)}
					</Grid>
				);
			case "LVD":
				return (
					<Grid container spacing={2}>
						<Grid item xs={12} md={3}>
							<TextField
								type="number"
								label="Length"
								fullWidth
								id={`${uniqueId}_length`}
								value={formData[`${uniqueId}_length`] || ""}
								onChange={(e) =>
									handleInputChange(`${uniqueId}_length`, e.target.value)
								}
								disabled={isReadOnly} // Disable if read-only
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<TextField
								type="number"
								label="Width"
								fullWidth
								id={`${uniqueId}_width`}
								value={formData[`${uniqueId}_width`] || ""}
								onChange={(e) =>
									handleInputChange(`${uniqueId}_width`, e.target.value)
								}
								disabled={isReadOnly} // Disable if read-only
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<TextField
								type="number"
								label="Depth"
								fullWidth
								id={`${uniqueId}_depth`}
								value={formData[`${uniqueId}_depth`] || ""}
								onChange={(e) =>
									handleInputChange(`${uniqueId}_depth`, e.target.value)
								}
								disabled={isReadOnly} // Disable if read-only
							/>
						</Grid>
						{question.unit_type && (
							<Grid item xs={12} md={3}>
								<Typography>{question.unit_type}</Typography>
							</Grid>
						)}
					</Grid>
				);
			case "text":
			case "comment":
				return (
					<TextField
						fullWidth
						id={uniqueId}
						value={formData[uniqueId] || ""}
						onChange={(e) => handleInputChange(uniqueId, e.target.value)}
						disabled={isReadOnly} // Disable if read-only
					/>
				);
			case "textarea":
				return (
					<TextField
						fullWidth
						multiline
						rows={4}
						id={uniqueId}
						value={formData[uniqueId] || ""}
						onChange={(e) => handleInputChange(uniqueId, e.target.value)}
						disabled={isReadOnly} // Disable if read-only
					/>
				);
			case "list":
				return (
					<FormControl fullWidth required={isRequired} disabled={isReadOnly}>
						<InputLabel id={`select-label-${uniqueId}`}>
							{question.question}
						</InputLabel>
						<Select
							labelId={`select-label-${uniqueId}`}
							id={uniqueId}
							label={question.question}
							value={formData[uniqueId] || ""}
							onChange={(e) => handleInputChange(uniqueId, e.target.value)}
							disabled={isReadOnly} // Disable if read-only
						>
							{question.select_list?.map((option) => (
								<MenuItem key={option} value={option}>
									{option}
								</MenuItem>
							))}
						</Select>
						{question.s_validate &&
							question.s_validate === "YES" &&
							formData[uniqueId] === question.s_reject_value && (
								<Typography variant="body2" color="error">
									Please make another selection to proceed to submit.
								</Typography>
							)}
					</FormControl>
				);
			default:
				return null;
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsSubmitting(true);

		try {
			// Check if a photo is uploaded (only required for non-partial submit)
			if (!uploadedPhoto && !isPartialSubmit) {
				setFileError("Please upload a photo before submitting.");
				throw new Error("Photo required");
			}

			// Validate location data (only required for non-partial submit)
			if (!isPartialSubmit && (!formData.latitude || !formData.longitude)) {
				setSnackbarMessage(
					"Location data is required. Please provide latitude and longitude values."
				);
				setSnackbarOpen(true);
				throw new Error("Location required");
			}

			// Basic validation for latitude (-90 to 90) and longitude (-180 to 180)
			if (!isPartialSubmit && formData.latitude && formData.longitude) {
				if (
					formData.latitude < -90 ||
					formData.latitude > 90 ||
					formData.longitude < -180 ||
					formData.longitude > 180
				) {
					setSnackbarMessage(
						"Please enter valid latitude (-90 to 90) and longitude (-180 to 180) values."
					);
					setSnackbarOpen(true);
					throw new Error("Invalid coordinates");
				}
			}

			const invalidSelection =
				results.gQuestion?.some(
					(q) =>
						q.s_validate &&
						q.s_validate === "YES" &&
						formData[q.uid] === q.s_reject_value
				) ||
				results.iQuestion?.some(
					(q) =>
						q.s_validate &&
						q.s_validate === "YES" &&
						formData[q.uid] === q.s_reject_value
				);

			if (invalidSelection) {
				setSnackbarMessage(
					"Please make another selection to proceed to submit."
				);
				setSnackbarOpen(true);
				throw new Error("Invalid selection");
			}

			await proceedWithSubmission();
		} catch (error) {
			console.error("Form submission error:", error);
			setIsSubmitting(false);
			return;
		}
	};

	const proceedWithSubmission = async () => {
		const params = {
			work_demand_id: parseInt(data.results.work_demand_id),
			estimated_amount: formData.variable_amount
				? parseInt(formData.variable_amount).toFixed(2)
				: "",
			upload_file_old: data.form_data?.upload_file || "",
			latitude: formData.latitude ? parseFloat(formData.latitude) : "",
			longitude: formData.longitude ? parseFloat(formData.longitude) : "",
			work_name: formData.work_name || "",
			address: formData.address || "",
			job_card_id: formData.job_card_id || "",
			drive_tag_id: formData.drive_tag_id || "",
			partial_submit: queryParams.get("partialSubmit") === "1" ? "YES" : "NO",
			gQuestion: data.results.gQuestion
				? data.results.gQuestion.map((q) => {
						return {
							work_group_id: q.work_group_id,
							group_question_id: q.group_question_id,
							measurement_id: q.measurement_id,
							selected_value: formData[q.uid] || "",
							number: formData[`${q.uid}_number`] || "",
							length: formData[`${q.uid}_length`] || "",
							width: formData[`${q.uid}_width`] || "",
							depth: formData[`${q.uid}_depth`] || "",
							text_value: formData[q.uid] || "",
							uid: q.uid,
						};
				  })
				: [],
			iQuestion: data.results.iQuestion
				? data.results.iQuestion.map((q) => {
						return {
							work_question_id: q.work_question_id,
							group_question_id: q.group_question_id,
							measurement_id: q.measurement_id,
							selected_value: formData[q.uid] || "",
							number: formData[`${q.uid}_number`] || "",
							length: formData[`${q.uid}_length`] || "",
							width: formData[`${q.uid}_width`] || "",
							depth: formData[`${q.uid}_depth`] || "",
							text_value: formData[q.uid] || "",
							uid: q.uid,
						};
				  })
				: [],
			work_sc1_id: workFilters.category,
			work_sc2_id: workFilters.subType1,
			work_sc3_id: workFilters.subType2,
			work_sc4_id: workFilters.subType3,
			work_sc5_id: workFilters.subType4,
		};

		try {
			const result = await completePreMeasurement(params, uploadedPhoto); // Pass the uploaded photo
			setSnackbarMessage("Pre-Measurement request submitted successfully");
			setSnackbarOpen(true);

			setTimeout(() => {
				window.location.href = "/core/pre-measurement-list";
			}, 2000);
		} catch (error) {
			console.error("Error submitting pre-measurement form:", error);
			setIsSubmitting(false); // Re-enable the button if there's an error
			// Handle error (e.g., show error message to user)
		}
	};

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
		setSnackbarMessage(""); // Reset snackbar message
	};

	const handleFileChange = async (event) => {
		const selectedFile = event.target.files[0];

		if (selectedFile) {
			try {
				const { file: compressedFile, previewUrl } = await compressImage(
					selectedFile,
					{
						maxWidth: 1920,
						maxHeight: 1080,
						quality: 0.7,
					}
				);

				// Check final size
				if (compressedFile.size > 10 * 1024 * 1024) {
					setFileError(
						"File size still exceeds 10MB after compression. Please use a smaller image."
					);
					setUploadedPhoto(null);
					setUploadedPhotoUrl(null);
				} else {
					setFileError("");
					setUploadedPhoto(compressedFile);
					setUploadedPhotoUrl(previewUrl);
				}
			} catch (error) {
				console.error("Error compressing image:", error);
				setFileError("Error processing image. Please try again.");
				setUploadedPhoto(null);
				setUploadedPhotoUrl(null);
			}
		}
	};

	const handleFilterChange = (filters) => {
		setWorkFilters(filters);
	};

	const { results } = data;
	const BASE_IMAGE_URL = data.img_path;

	const renderSnackbar = () => (
		<Snackbar
			open={snackbarOpen}
			autoHideDuration={6000}
			onClose={handleSnackbarClose}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
		>
			<Paper
				elevation={3}
				sx={{
					backgroundColor: "white",
					padding: "16px",
					maxWidth: "80vw",
					whiteSpace: "pre-line",
				}}
			>
				<Typography color="primary">{snackbarMessage}</Typography>
			</Paper>
		</Snackbar>
	);

	return (
		<Paper elevation={3} style={{ padding: "20px", margin: "20px" }}>
			<Typography
				variant="h4"
				gutterBottom
				className="text-2xl font-bold ml-10 xs:ml-40 sm:ml-0 xs:pt-2 pt-1"
			>
				Pre-Measurement Form
			</Typography>
			<Typography variant="h6" gutterBottom className="font-bold">
				Selected Work Details
			</Typography>
			<div className="flex flex-col mb-6 xs:flex-row">
				<div className="w-full mb-4 xs:w-1/2 xs:mr-4">
					{results.work_image && (
						<img
							src={`${BASE_IMAGE_URL}${results.work_image}`}
							alt={results.work_title}
							className="w-full h-auto mb-4 max-h-[300px] object-fill"
						/>
					)}
				</div>
				<div className="w-full xs:w-1/2">
					<Typography variant="body1" gutterBottom>
						<strong>Work Demand Code :</strong> {results.work_code}
					</Typography>

					<Typography variant="body1" gutterBottom>
						<strong>Work Title:</strong> {results.work_title}
					</Typography>

					<Typography variant="body1" gutterBottom>
						<strong>Category Title:</strong> {results.category_title}
					</Typography>

					<Typography>
						<strong>Work Type:</strong> {results.work_type_name}
					</Typography>
					<Typography>
						<strong>Description:</strong> {results.description}
					</Typography>
					<hr className="my-2.5 border-t border-gray-200" />
					<Typography>
						<strong>Amount type:</strong> {results.amount_type}
					</Typography>
					<Typography>
						<strong>Wage:</strong>
						{results.amount_type === "FIXED"
							? ` ₹${results.wage}`
							: ` ${results.wage}%`}
					</Typography>
					<Typography>
						<strong>Material:</strong>
						{results.amount_type === "FIXED"
							? ` ₹${results.material}`
							: ` ${results.material}%`}
					</Typography>
				</div>
			</div>
			<hr className="my-4 border-t border-gray-300" />
			{results.reason && (
				<>
					<Typography
						variant="body1"
						gutterBottom
						className="bg-amber-100 p-3 rounded-md mb-4"
					>
						{results.reason}
					</Typography>
					<hr className="my-4 border-t border-gray-300" />
				</>
			)}
			<form onSubmit={handleSubmit} method="POST" encType="multipart/form-data">
				<Typography variant="h6" gutterBottom className="font-bold">
					Work Categories
				</Typography>
				<WorkFilters
					onFilterChange={handleFilterChange}
					initialFilters={memoizedWorkFilters}
					isReadOnly={isReadOnly}
					requireValidation={true}
				/>
				<hr className="my-4 border-t border-gray-300" />

				<Typography variant="h6" gutterBottom className="font-bold">
					Location Details
				</Typography>
				<div className="flex flex-col sm:flex-row gap-0 sm:gap-8">
					<div className="flex flex-col w-full sm:w-auto">
						<Typography variant="body1" gutterBottom>
							<strong>District Name: </strong>
							{results.zp_name}
						</Typography>
						<Typography variant="body1" gutterBottom>
							<strong>Taluk Name: </strong>
							{results.tp_name}
						</Typography>
					</div>
					<div className="flex flex-col w-full sm:w-auto">
						<Typography variant="body1" gutterBottom>
							<strong>Gram Panchayat Name: </strong>
							{results.gp_name}
						</Typography>
						{results.village_name && (
							<Typography variant="body1" gutterBottom>
								<strong>Village Name: </strong>
								{results.village_name}
							</Typography>
						)}
					</div>
				</div>
				{!isMobile ? (
					<Grid container spacing={2} style={{ marginBottom: "20px" }}>
						<Grid item xs={12}>
							<Typography variant="h6" className="font-bold mb-4">
								Location Details
								{queryParams.get("partialSubmit") !== "1" && (
									<span className="text-red-500">*</span>
								)}
							</Typography>
						</Grid>
						{isPartialSubmit ? null : (
							<Grid item xs={12}>
								<Typography variant="subtitle1" className="font-bold mb-2">
									Address
								</Typography>
								<TextField
									label="Enter Address"
									fullWidth
									multiline
									rows={2}
									value={formData.address || ""}
									onChange={(e) => handleInputChange("address", e.target.value)}
									disabled={isReadOnly}
									className="mb-4"
								/>
							</Grid>
						)}

						{isPartialSubmit ? null : (
							<>
								<Grid item xs={12}>
									<Typography variant="subtitle1" className="font-bold mb-2">
										Coordinates
										{queryParams.get("partialSubmit") !== "1" && (
											<span className="text-red-500">*</span>
										)}
									</Typography>
								</Grid>
								<Grid item xs={12} md={6}>
									<TextField
										required={queryParams.get("partialSubmit") !== "1"}
										label="Latitude"
										type="number"
										fullWidth
										value={formData.latitude || ""}
										onChange={(e) =>
											handleLocationChange(e.target.value, formData.longitude)
										}
										disabled={isReadOnly}
										error={
											!formData.latitude &&
											queryParams.get("partialSubmit") !== "1"
										}
										helperText={
											!formData.latitude &&
											queryParams.get("partialSubmit") !== "1" &&
											"Latitude is required"
										}
										inputProps={{
											step: "any",
											min: -90,
											max: 90,
										}}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<TextField
										required={queryParams.get("partialSubmit") !== "1"}
										label="Longitude"
										type="number"
										fullWidth
										value={formData.longitude || ""}
										onChange={(e) =>
											handleLocationChange(formData.latitude, e.target.value)
										}
										disabled={isReadOnly}
										error={
											!formData.longitude &&
											queryParams.get("partialSubmit") !== "1"
										}
										helperText={
											!formData.longitude &&
											queryParams.get("partialSubmit") !== "1" &&
											"Longitude is required"
										}
										inputProps={{
											step: "any",
											min: -180,
											max: 180,
										}}
									/>
								</Grid>
							</>
						)}
					</Grid>
				) : (
					<LocationAccess
						onLocationChange={handleLocationChange}
						disabled={isReadOnly}
						currentLocation={{
							latitude: formData.latitude,
							longitude: formData.longitude,
						}}
						required={queryParams.get("partialSubmit") !== "1"}
					/>
				)}
				{results.remarks && (
					<>
						<hr className="my-4 border-t border-gray-300" />
						<Typography variant="h6" gutterBottom className="font-bold">
							Remarks
						</Typography>
						<Typography variant="body1" gutterBottom>
							{results.remarks}
						</Typography>
					</>
				)}
				<hr className="my-4 border-t border-gray-300" />
				{(results.gQuestion ||
					results.iQuestion ||
					results.amount_type === "VARIABLE") && (
					<Typography variant="h6" gutterBottom className="font-bold">
						Questionnaire
					</Typography>
				)}
				{results.amount_type === "VARIABLE" && (
					<Grid container spacing={2} style={{ marginBottom: "20px" }}>
						<Grid item xs={12} md={10}>
							<Typography variant="subtitle1">
								Estimated Amount in Rupees
							</Typography>
						</Grid>
						<Grid item xs={12} md={10}>
							<TextField
								type="number"
								fullWidth
								id="variable_amount"
								value={formData.variable_amount || ""}
								onChange={(e) =>
									handleInputChange("variable_amount", e.target.value)
								}
								error={!!amountError}
								helperText={amountError}
								InputProps={{
									endAdornment: (
										<InputAdornment position="start">₹</InputAdornment>
									),
									inputProps: { min: 10000 },
								}}
								disabled={isReadOnly}
								required
							/>
						</Grid>
					</Grid>
				)}
				{/* Only show gQuestion and iQuestion if NOT on partial-submit path */}
				{!(queryParams.get("partialSubmit") === "1") && (
					<>
						{/* Render gQuestion fields */}
						{results.gQuestion &&
							results.gQuestion.map((question) => (
								<Grid
									container
									spacing={2}
									key={question.uid}
									style={{ marginBottom: "20px" }}
								>
									<Grid item xs={12}>
										<Typography variant="subtitle1">
											{question.question}
											{question.measurement_id === "65" && (
												<a
													href="https://nregastrep.nic.in/netnrega/State_html/Jobcard_search_report.aspx?lflag=eng&state_code=15&state_name=KARNATAKA&page=S&fin_year=2024-2025&Digest=bGv35yky8JjJkUJ82OiBoQ"
													target="_blank"
													rel="noopener noreferrer"
													style={{
														marginLeft: "10px",
														color: "blue",
														textDecoration: "underline",
													}}
												>
													Check Limit {">>"}
												</a>
											)}
										</Typography>
									</Grid>
									<Grid item xs={12}>
										{renderField(question, "gQuestion")}
									</Grid>
								</Grid>
							))}
						{/* Render iQuestion fields */}
						{results.iQuestion &&
							results.iQuestion.map((question) => (
								<Grid
									container
									spacing={2}
									key={question.uid}
									style={{ marginBottom: "20px" }}
								>
									<Grid item xs={12}>
										<Typography variant="subtitle1">
											{question.question}
											{question.measurement_id === "65" && (
												<a
													href="https://nregastrep.nic.in/netnrega/State_html/Jobcard_search_report.aspx?lflag=eng&state_code=15&state_name=KARNATAKA&page=S&fin_year=2024-2025&Digest=bGv35yky8JjJkUJ82OiBoQ"
													target="_blank"
													rel="noopener noreferrer"
													style={{
														marginLeft: "10px",
														color: "blue",
														textDecoration: "underline",
													}}
												>
													Check Limit {">>"}
												</a>
											)}
										</Typography>
									</Grid>
									<Grid item xs={12}>
										{renderField(question, "iQuestion")}
									</Grid>
								</Grid>
							))}
					</>
				)}
				{isPartialSubmit ? null : (
					<Grid container spacing={2} style={{ marginBottom: "20px" }}>
						<Grid item xs={12}>
							<Typography variant="subtitle1">
								Upload Photo
								{queryParams.get("partialSubmit") !== "1" && (
									<span className="text-red-500">*</span>
								)}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<PhotoUpload
								onPhotoChange={handleFileChange}
								error={fileError}
								disabled={isReadOnly}
								helperText={fileError}
								required={queryParams.get("partialSubmit") !== "1"}
							/>
							{uploadedPhotoUrl && (
								<img
									src={uploadedPhotoUrl}
									alt="Uploaded Photo"
									style={{
										maxWidth: "100%",
										maxHeight: "200px",
										marginTop: "10px",
									}}
								/>
							)}
						</Grid>
					</Grid>
				)}
				<Grid container spacing={2} style={{ marginBottom: "20px" }}>
					<Grid item xs={12}>
						<Typography variant="subtitle1">Work Name</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							id="work_name"
							value={formData.work_name || ""}
							onChange={(e) => handleInputChange("work_name", e.target.value)}
							disabled={isReadOnly} // Disable if read-only
							required
						/>
					</Grid>
				</Grid>
				{results.drive_tags && results.drive_tags.length > 0 && (
					<Grid container spacing={2} style={{ marginBottom: "20px" }}>
						<Grid item xs={12}>
							<Typography variant="subtitle1">Select Special Drive</Typography>
						</Grid>
						<Grid item xs={12}>
							<FormControl fullWidth>
								<InputLabel id="select-drive-tag-label">
									Special Drive
								</InputLabel>
								<Select
									labelId="select-drive-tag-label"
									id="select-drive-tag"
									value={formData.drive_tag_id || ""}
									onChange={(e) =>
										handleInputChange("drive_tag_id", e.target.value)
									}
									disabled={isReadOnly} // Disable if read-only
								>
									{results.drive_tags.map((tag) => (
										<MenuItem key={tag.drive_tag_id} value={tag.drive_tag_id}>
											{tag.drive_tag_name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				)}

				{results.work_type_id === "1" && !results.job_card_id && (
					<Grid container spacing={2} style={{ marginBottom: "20px" }}>
						<Grid item xs={12}>
							<Typography variant="subtitle1">Select Job Card</Typography>
						</Grid>
						<Grid item xs={12}>
							<JobCardSelector
								villageId={results.village_id}
								onJobCardSelect={handleJobCardSelect}
								selectedJobCard={selectedJobCard}
							/>
						</Grid>
					</Grid>
				)}
				{results.work_type_id === "1" && results.job_card_id && (
					<Grid container spacing={2} style={{ marginBottom: "20px" }}>
						<Grid item xs={12}>
							<Typography variant="subtitle1">
								Job Code : {results.job_code}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="subtitle1">
								Head of the house : {results.head_of_house}
							</Typography>
						</Grid>
					</Grid>
				)}
				{!location.pathname.includes("core/premeasurement-data") && (
					<Grid container justifyContent="space-between">
						<Button
							variant="outlined"
							className="mr-2"
							color="primary"
							startIcon={<ArrowBack />}
							onClick={() => window.location.reload()}
						>
							Back
						</Button>
						{/* <Button
            variant="contained"
            className="mr-2"
            color="error"
            onClick={() => onReject(data.results.work_demand_id)}
          >
            Reject
          </Button> */}
						{/* TODO: Implement reject logic here */}
						{/* {!isMobile && (
							<Typography
								variant="body2"
								color="error"
								style={{ marginBottom: "20px" }}
							>
								Please submit this form using a mobile device.
							</Typography>
						)} */}
						<Button
							type="submit"
							variant="contained"
							color="success"
							disabled={!!amountError || isSubmitting || isReadOnly} // Disable submit if read-only
						>
							{isSubmitting ? "Submitting..." : "Submit"}
						</Button>
					</Grid>
				)}
			</form>
			{renderSnackbar()}
		</Paper>
	);
};

export default PreMeasurementForm;
