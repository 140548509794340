import { DOMAIN_URL, getAuthHeaders } from "utils/config";

export async function generateOTP(params: {
	user_name: string;
	new_user: boolean;
}) {
	const formData = new URLSearchParams();
	formData.append("input_data", JSON.stringify(params));

	const response = await fetch(`${DOMAIN_URL}/generate-otp`, {
		method: "POST",
		headers: getAuthHeaders(),
		body: formData,
	});

	if (!response.ok) {
		throw new Error("Failed to generate OTP");
	}

	return response.json();
}
