import React, { useEffect, useState } from "react";
import {
	Alert,
	Dialog,
	DialogActions,
	Button,
	DialogContent,
} from "@mui/material";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";

interface CommencementStatusAlertProps {
	gpId: string | number;
	open: boolean;
	onResponse: (canProceed: boolean) => void;
}

const CommencementStatusAlert: React.FC<CommencementStatusAlertProps> = ({
	gpId,
	open,
	onResponse,
}) => {
	const [alertMessage, setAlertMessage] = useState("");
	const [isBlocked, setIsBlocked] = useState(false);
	const [showDialog, setShowDialog] = useState(false);

	useEffect(() => {
		if (open) {
			checkCommencementStatus();
		} else {
			setShowDialog(false);
			setIsBlocked(false);
			setAlertMessage("");
		}
	}, [open, gpId]);

	const checkCommencementStatus = async () => {
		try {
			const commencementResponse = await fetch(
				`${DOMAIN_URL}/commencement/${gpId}`,
				{
					method: "GET",
					headers: getAuthHeaders(),
				}
			);

			const commencementData = await commencementResponse.json();
			let shouldBlock = false;

			if (
				commencementData.status &&
				commencementData.results?.commencement_date === null
			) {
				shouldBlock = true;
			} else if (
				commencementData.status &&
				commencementData.results?.commencement_date
			) {
				const commencementDate = new Date(
					commencementData.results.commencement_date
				);
				const currentDate = new Date();
				const commencementDatePlusTwo = new Date(
					commencementDate.getTime() + 7 * 24 * 60 * 60 * 1000
				);

				if (commencementDatePlusTwo < currentDate) {
					shouldBlock = true;
				}
			}

			if (shouldBlock) {
				setAlertMessage(
					"Demand entry has been closed for the financial year 2025-2026. Further, please contact your Zilla Panchayat / RD Commissionerate Office"
				);
				setIsBlocked(true);
				setShowDialog(true);
				onResponse(false);
			} else {
				onResponse(true);
			}
		} catch (error) {
			console.error("Error checking commencement status:", error);
			setAlertMessage(
				"An error occurred while checking status. Please try again."
			);
			setIsBlocked(true);
			setShowDialog(true);
			onResponse(false);
		}
	};

	const handleClose = () => {
		setShowDialog(false);
		onResponse(false);
	};

	return open ? (
		<Dialog open={showDialog} onClose={handleClose}>
			<DialogContent>
				<Alert severity="warning" sx={{ width: "100%" }}>
					{alertMessage}
				</Alert>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Close</Button>
			</DialogActions>
		</Dialog>
	) : null;
};

export default CommencementStatusAlert;
