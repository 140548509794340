import React, { useEffect, useState } from "react";
import {
	Autocomplete,
	TextField,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	IconButton,
	Typography,
	Button,
} from "@mui/material";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";
import { Edit, Save, Visibility } from "@mui/icons-material";
import Cookies from "js-cookie";
import CaptureAndUploadDialog from "../common/CaptureAndUploadDialog";
import { PhotoCamera } from "@mui/icons-material";

const GpCommencement = () => {
	const [tpList, setTpList] = useState([]);
	const [selectedTp, setSelectedTp] = useState(null);
	const [commencementData, setCommencementData] = useState([]);
	const [editingGpId, setEditingGpId] = useState(null); // Track the currently editing GP ID
	const [tableSearchTerm, setTableSearchTerm] = useState(""); // New state for table search term
	const [openPhotoDialog, setOpenPhotoDialog] = useState(false);
	const [selectedGpForPhoto, setSelectedGpForPhoto] = useState(null);
	const [selectedGp, setSelectedGp] = useState(null);
	const [imageBaseUrl, setImageBaseUrl] = useState("");
	const [currentRole, setCurrentRole] = useState(null);
	const [frontSheetHtml, setFrontSheetHtml] = useState(null);

	const fetchCurrentRole = async () => {
		const roleId = Cookies.get("role_id");
		if (!roleId) return null;
		try {
			const response = await fetch(`${DOMAIN_URL}/user-roles`, {
				method: "GET",
				headers: getAuthHeaders(),
			});

			if (!response.ok) throw new Error("Failed to fetch user roles");

			const data = await response.json();
			if (data.status && Array.isArray(data.results)) {
				return data.results.find((role) => role.role_id === roleId) || null;
			}
		} catch (error) {
			console.error("Error fetching current role:", error);
		}
		return null;
	};

	const fetchTpList = async (zp_id = "") => {
		try {
			const response = await fetch(
				`${DOMAIN_URL}/tp-list${zp_id ? "/" + zp_id : ""}`,
				{
					method: "GET",
					headers: getAuthHeaders(),
				}
			);
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			if (data.status) {
				setTpList(data.results);
			}
		} catch (error) {
			console.error("Error fetching job cards:", error);
		}
	};

	useEffect(() => {
		const initializeData = async () => {
			const role = await fetchCurrentRole();
			setCurrentRole(role);

			// Check if user is admin level (no specific GP/TP/ZP assignment)
			const isAdmin = !role?.gp_id && !role?.tp_id && !role?.zp_id;

			if (role?.gp_id) {
				// GP user - set GP data and fetch its commencement data
				setSelectedGp({ gp_id: role.gp_id, gp_name: role.gp_name });
				setSelectedTp(null);
				setTpList([]);

				try {
					const response = await fetch(
						`${DOMAIN_URL}/commencement/${role.gp_id}`,
						{
							method: "GET",
							headers: getAuthHeaders(),
						}
					);
					if (response.ok) {
						const data = await response.json();
						if (data.status) {
							setImageBaseUrl(data.img_path || "");
							const resultsArray = Array.isArray(data.results)
								? data.results
								: [data.results];
							setCommencementData(resultsArray);
						}
					}
				} catch (error) {
					console.error("Failed to fetch commencement data:", error);
					setCommencementData([]);
				}
			} else if (role?.tp_id) {
				// TP user
				setSelectedTp({ tp_id: role.tp_id, tp_name: role.tp_name });
				setTpList([]);

				try {
					const response = await fetch(
						`${DOMAIN_URL}/gp-commencement/${role.tp_id}`,
						{
							method: "GET",
							headers: getAuthHeaders(),
						}
					);
					if (response.ok) {
						const data = await response.json();
						if (data.status) {
							setImageBaseUrl(data.img_path || "");
							const resultsArray = Array.isArray(data.results)
								? data.results
								: [data.results];
							setCommencementData(resultsArray);
						}
					}
				} catch (error) {
					console.error("Failed to fetch commencement data:", error);
					setCommencementData([]);
				}
			} else if (role?.zp_id) {
				fetchTpList(role.zp_id);
			} else if (isAdmin) {
				// Admin level user can see all TPs
				fetchTpList();
			}
		};

		initializeData();
	}, []);

	const handleTpSelect = async (event, value) => {
		if (!value) {
			setSelectedTp(null);
			setCommencementData([]);
			return;
		}

		setSelectedTp(value);

		try {
			const response = await fetch(
				`${DOMAIN_URL}/gp-commencement/${value.tp_id}`,
				{
					method: "GET",
					headers: getAuthHeaders(),
				}
			);
			if (!response.ok) throw new Error("Network response was not ok");

			const data = await response.json();
			if (data.status) {
				setImageBaseUrl(data.img_path || "");
				setCommencementData(data.results);
			}
		} catch (error) {
			console.error("Error fetching commencement data:", error);
			setCommencementData([]);
		}
	};

	const handleDateEdit = (gp_id, date) => {
		// Update the commencement date in the state
		setCommencementData((prevData) =>
			prevData.map((gp) =>
				gp.gp_id === gp_id ? { ...gp, commencement_date: date } : gp
			)
		);
	};

	const filteredCommencementData = Array.isArray(commencementData)
		? commencementData.filter((gp) =>
				gp.gp_name.toLowerCase().includes(tableSearchTerm.toLowerCase())
		  )
		: [];

	const handleTableSearch = (event) => {
		setTableSearchTerm(event.target.value); // Update table search term
	};

	const handleSaveCommencementDate = async (gp_id, commencement_date) => {
		try {
			const url = `${DOMAIN_URL}/gp-commencement-update`;
			const inputData = JSON.stringify({ gp_id, commencement_date });

			const params = new URLSearchParams();
			params.append("input_data", inputData);

			const response = await fetch(url, {
				method: "POST",
				headers: getAuthHeaders(),
				body: params,
			});

			if (!response.ok) {
				const errorText = await response.text();
				console.error("Response error:", errorText);
				throw new Error(
					`HTTP error! status: ${response.status}, message: ${errorText}`
				);
			}

			const data = await response.json();
			if (!data.status) {
				throw new Error("Failed to update commencement date");
			}

			// Optionally, you can refresh the data or show a success message here
		} catch (error) {
			console.error("Error updating commencement date:", error);
		}
	};

	const handleOpenPhotoDialog = (gp_id) => {
		setSelectedGpForPhoto(gp_id);
		setOpenPhotoDialog(true);
	};

	const handlePhotoUploadComplete = (success, message) => {
		// You can add a toast notification here if you want
		console.log(message);
		setOpenPhotoDialog(false);
		setSelectedGpForPhoto(null);
	};

	const handleViewReport = async (gp_id, report_slug) => {
		try {
			const response = await fetch(`${DOMAIN_URL}/${report_slug}/${gp_id}`, {
				method: "GET",
				headers: getAuthHeaders(),
			});

			if (!response.ok) throw new Error("Failed to fetch front sheet");

			const data = await response.json();
			if (data.status) {
				// Create a new window and write the HTML content
				const newWindow = window.open("", "_blank");
				newWindow.document.write(data.results);
				newWindow.document.close();
			}
		} catch (error) {
			console.error("Error fetching front sheet:", error);
		}
	};

	return (
		<div>
			<div className="mb-4 pt-2 ml-10 xs:ml-10">
				<Typography variant="h6">GP Commencement Data</Typography>
			</div>
			<div className="flex flex-col xs:flex-row justify-between mb-4">
				{selectedGp ? (
					<Typography className="flex-grow ml-2 mt-4 xs:mr-2" variant="body1">
						<strong>{selectedGp.gp_name}</strong>
					</Typography>
				) : (
					<div className="flex-grow">
						{!currentRole?.gp_id && !currentRole?.tp_id && (
							<Autocomplete
								className="flex-grow xs:mr-2"
								id="talukPanchayat"
								options={tpList}
								getOptionLabel={(option) => option.tp_name || ""}
								value={selectedTp}
								onChange={handleTpSelect}
								isOptionEqualToValue={(option, value) =>
									option?.tp_id === value?.tp_id
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Taluk Panchayat"
										fullWidth
										margin="normal"
										required
									/>
								)}
								renderOption={(props, option) => (
									<li {...props} key={`tp-${option.tp_id}`}>
										{option.tp_name}
									</li>
								)}
							/>
						)}
						{selectedTp && (
							<Typography className="ml-2 mt-4 xs:mr-2" variant="body1">
								<strong>Selected: {selectedTp.tp_name}</strong>
							</Typography>
						)}
					</div>
				)}
				<TextField
					className="flex-grow xs:ml-2 w-full sm:w-64 mt-4"
					variant="outlined"
					placeholder="Search Table…"
					value={tableSearchTerm}
					onChange={handleTableSearch}
				/>
			</div>

			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>GP Code</TableCell>
							<TableCell>GP Name</TableCell>
							<TableCell>Budget Year</TableCell>
							<TableCell>Commencement Date</TableCell>
							<TableCell>Photo</TableCell>
							{commencementData.some((gp) => gp.remarks) && (
								<TableCell>Remarks</TableCell>
							)}
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{filteredCommencementData.map((gp) => (
							<TableRow key={gp.gp_id}>
								<TableCell>{gp.gp_code}</TableCell>
								<TableCell>{gp.gp_name}</TableCell>
								<TableCell>{gp.budget_year}</TableCell>
								<TableCell>
									<div className="flex items-center">
										{!currentRole?.gp_id && editingGpId === gp.gp_id ? (
											<>
												<TextField
													value={gp.commencement_date || ""}
													onChange={(e) =>
														handleDateEdit(gp.gp_id, e.target.value)
													}
													type="date"
													size="small"
													style={{ width: "120px" }}
												/>
												<IconButton
													onClick={() => {
														handleSaveCommencementDate(
															gp.gp_id,
															gp.commencement_date
														);
														setEditingGpId(null);
													}}
													className="ml-2 btn-icon rounded-full bg-orange-100 hover:bg-orange-600 text-orange-600 hover:text-white"
												>
													<Save />
												</IconButton>
											</>
										) : (
											<>
												{gp.commencement_date}
												{!currentRole?.gp_id && (
													<IconButton
														onClick={() => setEditingGpId(gp.gp_id)}
														className="ml-2 btn-icon rounded-full bg-orange-100 hover:bg-orange-600 text-orange-600 hover:text-white"
													>
														<Edit />
													</IconButton>
												)}
											</>
										)}
									</div>
								</TableCell>
								<TableCell>
									{gp.file_name ? (
										<div className="flex items-center">
											<a
												href={`${imageBaseUrl}${gp.file_name}`}
												target="_blank"
												rel="noopener noreferrer"
											>
												<img
													src={`${imageBaseUrl}${gp.file_name}`}
													alt="Commencement"
													style={{
														width: "50px",
														height: "50px",
														objectFit: "cover",
														cursor: "pointer",
													}}
												/>
											</a>
										</div>
									) : (
										"-"
									)}
								</TableCell>
								{commencementData.some((gp) => gp.remarks) && (
									<TableCell>{gp.remarks}</TableCell>
								)}

								<TableCell>
									<div className="flex items-center">
										<Button
											onClick={() => handleViewReport(gp.gp_id, "front-sheet")}
											className="ml-2 !py-0.25 !px-0.5 leading-tight"
											variant="contained"
											color="info"
											size="small"
										>
											Front sheet report
										</Button>
										<Button
											onClick={() =>
												handleViewReport(gp.gp_id, "approved-report")
											}
											className="ml-2 !py-0.25 !px-0.5 leading-tight"
											variant="contained"
											color="info"
											size="small"
										>
											Approved report
										</Button>
										{currentRole?.gp_id && (
											<Button
												onClick={() => handleOpenPhotoDialog(gp.gp_id)}
												className="ml-2 leading-tight"
												variant="contained"
												color="info"
												size="small"
												startIcon={<PhotoCamera />}
											>
												Upload Photo
											</Button>
										)}
									</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<CaptureAndUploadDialog
				open={openPhotoDialog}
				onClose={() => setOpenPhotoDialog(false)}
				onUploadComplete={handlePhotoUploadComplete}
			/>
		</div>
	);
};

export default GpCommencement;
