import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ChevronLeft, Warning } from "@mui/icons-material";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TablePagination,
	TableSortLabel,
	Checkbox,
	Button,
	Snackbar,
	Alert,
	TextField,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	CircularProgress,
	Box,
} from "@mui/material";
import { styled } from "@mui/system";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";
import { fetchWorkReasonList } from "pages/dashboard/api";
import ActionButtons from "./ActionButtons";
import CalculationTable from "./CalculationTable";
import { updateWorkStatus } from "./api/workStatusApi";
import SignDSCFlow from "../SignDSCFlow";

const StyledTableContainer = styled(TableContainer)({
	maxWidth: "100%",
	overflowX: "auto",
});

const StyledTableCell = styled(TableCell)({
	padding: "8px 16px",
	height: "40px",
	fontSize: "600",
	minWidth: "80px",
	whiteSpace: "nowrap",
});

const StyledTableHeadCell = styled(TableCell)({
	backgroundColor: "#1976d2",
	color: "#fff",
	padding: "8px 16px",
	whiteSpace: "nowrap",
	"& .MuiTableSortLabel-icon": {
		display: "none",
	},
	"& .MuiTableSortLabel-root.Mui-active": {
		color: "inherit",
	},
});

const StyledTableRow = styled(TableRow)({
	"&:hover": {
		backgroundColor: "rgb(243 244 246)",
	},
});

const StyledAlert = styled(Alert)(({ theme }) => ({
	"& .MuiAlert-message": {
		fontSize: "1rem",
		fontWeight: 500,
	},
	"& .MuiAlert-icon": {
		fontSize: "1.5rem",
	},
	minWidth: "300px",
}));

const WarningBox = styled(Box)({
	backgroundColor: "#fff3e0",
	border: "1px solid #ffb74d",
	borderRadius: "4px",
	padding: "12px",
	margin: "8px 16px",
	display: "flex",
	alignItems: "center",
	gap: "12px",
});

const StartVerificationWorkList = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [data, setData] = useState(null);
	const [pageTitle, setPageTitle] = useState("");
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [selected, setSelected] = useState([]);
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: "",
		severity: "success",
	});
	const [search, setSearch] = useState(""); // State for search query

	const [openAcceptAllDialog, setOpenAcceptAllDialog] = useState(false);
	const [openRejectAllDialog, setOpenRejectAllDialog] = useState(false);
	const [rejectReasons, setRejectReasons] = useState([]);
	const [selectedReasonId, setSelectedReasonId] = useState("");
	const [otherRejectReason, setOtherRejectReason] = useState("");
	const [pendingBulkAction, setPendingBulkAction] = useState(null);
	const [showDscModal, setShowDscModal] = useState(false);
	const [adminInfo, setAdminInfo] = useState(null);
	const [calculationData, setCalculationData] = useState(null);
	const [isLoading, setIsLoading] = useState(true); // Add loading state for initial data
	const [isCalculating, setIsCalculating] = useState(false); // Add loading state for calculations
	const [isDscLoading, setIsDscLoading] = useState(false);

	const pathParts = location.pathname.split("/");
	const currentPath = pathParts[pathParts.length - 2];
	const gpId = pathParts[pathParts.length - 1];

	const fetchData = async () => {
		setIsLoading(true); // Set loading before fetch
		try {
			const pathParts = location.pathname.split("/");
			let endpoint = pathParts[pathParts.length - 2];

			// Transform any workgp-list to work-list
			endpoint = endpoint.replace("workgp-list", "work-list");

			const fetchUrl = `${DOMAIN_URL}/${endpoint}/${gpId}`;
			const response = await fetch(fetchUrl, {
				method: "GET",
				headers: getAuthHeaders(),
			});
			if (!response.ok) throw new Error("Network response was not ok");
			const fetchedData = await response.json();

			// Set the page title regardless of status
			setPageTitle(fetchedData.key || "");

			// Only set other data if status is true and results exist
			if (fetchedData && fetchedData.status && fetchedData.results) {
				setData(fetchedData);
				setAdminInfo(fetchedData.admin_info);
				setCalculationData(fetchedData.calculation_list);
				setSelected(getInitiallySelectedRows(fetchedData.results));
				if (fetchedData.results.length > 0) {
					setOrderBy(Object.keys(fetchedData.results[0])[0]);
				}
			} else {
				// Clear other data if status is false
				setData(null);
				setAdminInfo(null);
				setCalculationData(null);
				setSelected([]);
			}
		} catch (error) {
			setData(null);
			setPageTitle("");
			setAdminInfo(null);
			setCalculationData(null);
			setSelected([]);
		} finally {
			setIsLoading(false); // Clear loading after fetch completes
		}
	};

	useEffect(() => {
		fetchData();
		setPage(0);
	}, [location]);

	const handleRequestSort = (property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleActionSuccess = (message) => {
		setSnackbar({
			open: true,
			message: message,
			severity: "success",
		});
		fetchData();
	};

	const handleActionError = (message) => {
		setSnackbar({
			open: true,
			message: message,
			severity: "error",
		});
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked && filteredResults) {
			// Select all rows instead of just the current page
			const newSelected = filteredResults.map((_, index) => index);
			setSelected(newSelected);

			// Update calculations with all work demands
			const selectedWorkDemands = filteredResults.map((row) =>
				Number(row.work_demand_id)
			);
			updateCalculations(selectedWorkDemands);
			return;
		}
		setSelected([]);
		updateCalculations([]);
	};

	const handleCheckboxClick = (event, index) => {
		event.stopPropagation();

		const selectedIndex = selected.indexOf(index);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = [...selected, index];
		} else {
			newSelected = selected.filter((i) => i !== index);
		}

		setSelected(newSelected);

		// Update calculations with the new selection
		const selectedWorkDemands = newSelected.map((index) =>
			Number(filteredResults[index].work_demand_id)
		);
		updateCalculations(selectedWorkDemands);
	};

	const isSelected = (index) => {
		return selected.indexOf(index) !== -1;
	};

	const shouldShowAcceptButton = () => {
		return (
			calculationData?.show_sign_dsc ?? data?.calculation_list?.show_sign_dsc
		);
	};

	const filteredResults =
		data?.results?.filter((row) => {
			return Object.values(row).some((value) =>
				String(value).toLowerCase().includes(search.toLowerCase())
			);
		}) || []; // Default to an empty array if undefined

	const selectedCount = selected.length;
	const totalCount = filteredResults.length;

	const selectedAcceptableCount = selected.filter((index) =>
		shouldShowAcceptButton(filteredResults[index])
	).length;

	const getAcceptButtonLabel = () => {
		let label = `Accept Selected (${selectedAcceptableCount}/${totalCount})`;
		if (location.pathname.includes("tpad-workgp-list")) {
			label = `Forward to EO (${selectedAcceptableCount}/${totalCount})`;
		} else if (location.pathname.includes("tpeo-workgp-list")) {
			label = `Forward to ZP (${selectedAcceptableCount}/${totalCount})`;
		} else if (location.pathname.includes("zp-workgp-list")) {
			label = `Forward to CEO (${selectedAcceptableCount}/${totalCount})`;
		} else if (location.pathname.includes("zpceo-workgp-list")) {
			label = `Sign Final Approval (${selectedAcceptableCount}/${totalCount})`;
		}
		return label;
	};

	const handleBulkAction = async (statusType) => {
		const workDemands = selected
			.filter(
				(index) =>
					statusType !== "ACCEPT" ||
					shouldShowAcceptButton(filteredResults[index])
			)
			.map((index) => Number(filteredResults[index].work_demand_id));

		if (workDemands.length === 0) {
			setSnackbar({
				open: true,
				message: `No eligible rows for ${statusType.toLowerCase()}ance`,
				severity: "warning",
			});
			return;
		}

		// For reject, fetch reasons and show reject dialog
		if (statusType === "REJECT") {
			await fetchRejectReasons(); // Fetch reasons before opening dialog
			setOpenRejectAllDialog(true);
			return;
		}

		// For accept, show DSC flow
		setPendingBulkAction({
			statusType,
			workDemands,
		});
		setShowDscModal(true);
	};

	const executeBulkAction = async (statusType, workDemands) => {
		try {
			const response = await updateWorkStatus({
				pathname: location.pathname,
				workDemands,
				statusType,
				// Add reject reason details if present in pendingBulkAction
				rejectReasonId: pendingBulkAction?.rejectReasonId,
				rejectReason: pendingBulkAction?.rejectReason,
			});

			if (response.status) {
				setSnackbar({
					open: true,
					message: response.message || `${statusType}ed successfully`,
					severity: "success",
				});
				setSelected([]);
			} else {
				setSnackbar({
					open: true,
					message: `Failed to ${statusType.toLowerCase()}: ${
						response.message || "Unknown error"
					}`,
					severity: "error",
				});
			}
			fetchData();
		} catch (error) {
			console.error(`Error during ${statusType.toLowerCase()}:`, error);
			setSnackbar({
				open: true,
				message: `Failed to ${statusType.toLowerCase()}`,
				severity: "error",
			});
		}
	};

	const handleDscSuccess = async () => {
		if (pendingBulkAction) {
			try {
				setIsDscLoading(true); // Start loading
				await executeBulkAction(
					pendingBulkAction.statusType,
					pendingBulkAction.workDemands
				);
			} finally {
				setIsDscLoading(false); // End loading
				setPendingBulkAction(null);
				setShowDscModal(false);
			}
		}
	};

	const handleAcceptAllConfirm = () => {
		setOpenAcceptAllDialog(false);
		handleBulkAction("ACCEPT");
	};

	const handleRejectAllConfirm = async () => {
		if (!selectedReasonId) return;
		setOpenRejectAllDialog(false);

		// After getting reject reason, proceed with DSC flow
		setPendingBulkAction({
			statusType: "REJECT",
			workDemands: selected.map((index) =>
				Number(filteredResults[index].work_demand_id)
			),
			// Add reject reason details
			rejectReasonId: selectedReasonId,
			rejectReason: selectedReasonId === "others" ? otherRejectReason : "",
		});
		setShowDscModal(true);
	};

	const fetchRejectReasons = async () => {
		try {
			// Extract list type from current URL path
			const pathParts = location.pathname.split("/");
			const listType = pathParts[pathParts.length - 2];

			const reasonList = await fetchWorkReasonList(listType);
			setRejectReasons([
				...reasonList,
				{ reject_reason_id: "others", reason_name: "Others" },
			]);
		} catch (error) {
			console.error("Error fetching reject reasons:", error);
			setSnackbar({
				open: true,
				message: "Failed to fetch reject reasons",
				severity: "error",
			});
		}
	};

	const rejectButtonLabel = `Reject Selected (${selectedCount}/${totalCount})`;

	const handleBack = () => {
		const pathParts = location.pathname.split("/");
		pathParts.pop(); // Remove the last part (GP ID)
		navigate(pathParts.join("/")); // Navigate to the URL without GP ID
	};

	const getActionButtonLabel = (statusType) => {
		if (statusType === "ACCEPT") {
			return getAcceptButtonLabel();
		}
		return `Reject Selected (${selectedCount}/${totalCount})`;
	};

	const getForwardedWorksPath = () => {
		// Map current path to the corresponding forwarded works path
		const pathMapping = {
			"tpad-workgp-list": "/core/tpad-forwarded-list",
			"tpeo-workgp-list": "/core/tpeo-forwarded-list",
			"zpdd-workgp-list": "/core/zpdd-forwarded-list",
			"zpds-workgp-list": "/core/zpds-forwarded-list",
			"zpceo-workgp-list": "/core/zpceo-forwarded-list",
			// Add other mappings as needed
		};

		const basePath = pathMapping[currentPath] || "/core/forwarded-works-list";
		return `${basePath}/${gpId}`;
	};

	const getRejectedWorksPath = () => {
		const pathParts = location.pathname.split("/");
		const currentPath = pathParts[pathParts.length - 2];
		const gpId = pathParts[pathParts.length - 1];

		// Map current path to the corresponding rejected works path
		const pathMapping = {
			"tpad-workgp-list": "/core/tpad-rejected-list",
			"tpeo-workgp-list": "/core/tpeo-rejected-list",
			"zpdd-workgp-list": "/core/zpdd-rejected-list",
			"zpds-workgp-list": "/core/zpds-rejected-list",
			"zpceo-workgp-list": "/core/zpceo-rejected-list",
			// Add other mappings as needed
		};

		const basePath = pathMapping[currentPath] || "/core/rejected-works-list";
		return `${basePath}/${gpId}`;
	};

	const updateCalculations = async (selectedWorkDemands) => {
		setIsCalculating(true); // Set calculating state before API call
		try {
			// Get work_mode from the current path
			const pathParts = location.pathname.split("/");
			const currentPath = pathParts[pathParts.length - 2];
			const workMode = currentPath.replace("-workgp-list", "").toUpperCase();

			// Create the input_data object
			const formData = new URLSearchParams();
			formData.append(
				"input_data",
				JSON.stringify({
					work_demands: selectedWorkDemands,
					work_mode: workMode,
					gp_id: gpId,
				})
			);

			const response = await fetch(`${DOMAIN_URL}/work-calculation`, {
				method: "POST",
				headers: {
					...getAuthHeaders(),
				},
				body: formData,
			});

			if (!response.ok) throw new Error("Network response was not ok");
			const result = await response.json();

			if (result.status) {
				setCalculationData(result.results);
			} else {
				throw new Error(result.message || "Failed to update calculations");
			}
		} catch (error) {
			console.error("Error updating calculations:", error);
			setSnackbar({
				open: true,
				message: "Failed to update calculations",
				severity: "error",
			});
		} finally {
			setIsCalculating(false); // Clear calculating state after completion
		}
	};

	// Add this function to get initially checked rows
	const getInitiallySelectedRows = (results) => {
		if (!results) return [];
		return results
			.map((row, index) => ({ index, status: row.checkbox_status }))
			.filter(({ status }) => status === "checked")
			.map(({ index }) => index);
	};

	// Add handlers for dialog close
	const handleRejectDialogClose = () => {
		setOpenRejectAllDialog(false);
		setSelectedReasonId(""); // Reset selected reason
		setOtherRejectReason(""); // Reset other reason text
	};

	const handleDscModalClose = () => {
		setShowDscModal(false);
		setPendingBulkAction(null);
	};

	return (
		<div>
			<Paper sx={{ width: "100%", mb: 2, pb: 2, overflowY: "scroll" }}>
				{/* Sticky Header Section */}
				<div>
					<div className="flex items-center mb-2">
						<Button
							onClick={handleBack}
							variant="outlined"
							sx={{
								ml: 2,
								mt: { xs: 6, sm: 1 }, // More top margin on mobile (xs), normal on sm and up
							}}
							className="back-button"
							startIcon={<ChevronLeft />}
						>
							Back
						</Button>
						<Typography
							variant="h6"
							component="div"
							className="text-2xl font-bold ml-4 xs:ml-40 sm:ml-4 xs:pt-2 pt-1"
						>
							{pageTitle}
						</Typography>
					</div>

					{/* Add the warning message here */}
					{calculationData?.approval_message && (
						<WarningBox>
							<Warning color="warning" />
							<Typography>{calculationData.approval_message}</Typography>
						</WarningBox>
					)}

					{/* Only show the rest of the content if data exists */}
					{isCalculating ? null : (
						<>
							{(calculationData || data?.calculation_list) && (
								<div className="px-4 relative">
									{isCalculating && (
										<div className="absolute inset-0 bg-white/50 z-10 flex justify-center items-center">
											<CircularProgress size={24} />
										</div>
									)}
									<CalculationTable
										calculationList={calculationData || data?.calculation_list}
									/>
								</div>
							)}

							<div className="flex flex-col sm:flex-row sm:justify-between items-start sm:items-center mb-2 mt-2 px-4">
								<div className="flex space-x-2 mb-2 sm:mb-0">
									{shouldShowAcceptButton() && (
										<Button
											variant="contained"
											color="success"
											onClick={() => handleBulkAction("ACCEPT")}
										>
											{getAcceptButtonLabel()}
										</Button>
									)}

									<Button
										variant="contained"
										color="error"
										onClick={() => handleBulkAction("REJECT")}
									>
										{rejectButtonLabel}
									</Button>
								</div>
								<div className="w-full sm:ml-auto sm:w-auto">
									<TextField
										variant="outlined"
										placeholder="Search…"
										value={search}
										onChange={(e) => setSearch(e.target.value)}
										className="w-full sm:w-64"
									/>
								</div>
							</div>
						</>
					)}
				</div>

				{isLoading ? (
					<div className="flex justify-center items-center p-8">
						<CircularProgress />
					</div>
				) : data ? (
					<>
						{isCalculating ? (
							<div className="flex justify-center items-center p-8">
								<CircularProgress />
							</div>
						) : (
							<Paper>
								<StyledTableContainer>
									<Table stickyHeader aria-label="sticky table">
										<TableHead>
											<StyledTableRow>
												<StyledTableHeadCell padding="checkbox">
													<Checkbox
														indeterminate={
															selected.length > 0 &&
															selected.length < filteredResults.length
														}
														checked={
															filteredResults.length > 0 &&
															selected.length > 0 &&
															selected.length === filteredResults.length
														}
														onChange={handleSelectAllClick}
														inputProps={{ "aria-label": "select all" }}
													/>
												</StyledTableHeadCell>

												<StyledTableHeadCell>Actions</StyledTableHeadCell>
												{filteredResults[0] &&
													Object.keys(filteredResults[0])
														.filter(
															(key) =>
																!key.includes("_id") &&
																key !== "checkbox_status"
														)
														.map((key) => (
															<StyledTableHeadCell
																key={key}
																sortDirection={orderBy === key ? order : false}
															>
																<TableSortLabel
																	active={orderBy === key}
																	direction={orderBy === key ? order : "asc"}
																	onClick={() => handleRequestSort(key)}
																>
																	{key.replaceAll("_", " ").toUpperCase()}
																</TableSortLabel>
															</StyledTableHeadCell>
														))}
											</StyledTableRow>
										</TableHead>
										<TableBody>
											{filteredResults
												?.slice(
													page * rowsPerPage,
													page * rowsPerPage + rowsPerPage
												)
												.map((row, paginatedIndex) => {
													const actualIndex =
														page * rowsPerPage + paginatedIndex;
													const isItemSelected = isSelected(actualIndex);
													return (
														<StyledTableRow
															hover
															role="checkbox"
															aria-checked={isItemSelected}
															tabIndex={-1}
															key={`${row.work_demand_id}-${actualIndex}`}
															selected={isItemSelected}
														>
															<StyledTableCell padding="checkbox">
																<Checkbox
																	checked={isItemSelected}
																	onChange={(event) =>
																		handleCheckboxClick(event, actualIndex)
																	}
																	inputProps={{
																		"aria-labelledby": `enhanced-table-checkbox-${actualIndex}`,
																	}}
																/>
															</StyledTableCell>
															<StyledTableCell>
																<ActionButtons
																	workDemandId={row.work_demand_id}
																	onSuccess={handleActionSuccess}
																	onError={handleActionError}
																/>
															</StyledTableCell>
															{Object.keys(row)
																.filter(
																	(key) =>
																		!key.includes("_id") &&
																		key !== "checkbox_status"
																)
																.map((key) => (
																	<StyledTableCell
																		key={`${actualIndex}-${key}`}
																	>
																		{row[key]}
																	</StyledTableCell>
																))}
														</StyledTableRow>
													);
												})}
										</TableBody>
									</Table>
								</StyledTableContainer>
								<TablePagination
									rowsPerPageOptions={[20, 50, 100]}
									component="div"
									count={filteredResults?.length || 0}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
								/>
							</Paper>
						)}
					</>
				) : (
					<div>
						<Typography
							variant="h6"
							component="div"
							className="text-2xl font-bold ml-4 xs:ml-40 sm:ml-4 xs:pt-2 pt-1"
						>
							No data found
						</Typography>
					</div>
				)}

				<div className="flex justify-center space-x-4 mt-4 mb-8">
					<Button
						variant="contained"
						color="success"
						className="px-4 py-2"
						onClick={() => navigate(getForwardedWorksPath())}
					>
						View Forwarded GP Works List
					</Button>
					<Button
						variant="contained"
						color="error"
						className="px-4 py-2"
						onClick={() => navigate(getRejectedWorksPath())}
					>
						View Rejected GP Works List
					</Button>
				</div>

				<Snackbar
					open={snackbar.open}
					autoHideDuration={3000}
					onClose={() => setSnackbar({ ...snackbar, open: false })}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					sx={{
						position: "fixed",
						zIndex: 9999,
						top: "24px !important",
						left: "50% !important",
						transform: "translateX(-50%)",
					}}
				>
					<StyledAlert
						onClose={() => setSnackbar({ ...snackbar, open: false })}
						severity={snackbar.severity}
						elevation={6}
						variant="filled"
					>
						{snackbar.message}
					</StyledAlert>
				</Snackbar>

				{/* Accept All Confirmation Dialog */}
				<Dialog
					open={openAcceptAllDialog}
					onClose={() => setOpenAcceptAllDialog(false)}
				>
					<DialogTitle>Confirm {getAcceptButtonLabel()}</DialogTitle>
					<DialogContent>
						Are you sure you want to {getAcceptButtonLabel().toLowerCase()}?
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setOpenAcceptAllDialog(false)}>
							Cancel
						</Button>
						<Button onClick={handleAcceptAllConfirm} color="primary">
							Confirm
						</Button>
					</DialogActions>
				</Dialog>

				{/* Reject All Dialog */}
				<Dialog open={openRejectAllDialog} onClose={handleRejectDialogClose}>
					<DialogTitle>{rejectButtonLabel}</DialogTitle>
					<DialogContent>
						<FormControl fullWidth margin="normal">
							<InputLabel id="reject-reason-label">Reject Reason</InputLabel>
							<Select
								labelId="reject-reason-label"
								value={selectedReasonId}
								onChange={(e) => setSelectedReasonId(e.target.value)}
								label="Reject Reason"
							>
								{rejectReasons.map((reason) => (
									<MenuItem
										key={reason.reject_reason_id}
										value={reason.reject_reason_id}
									>
										{reason.reason_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						{selectedReasonId === "others" && (
							<TextField
								fullWidth
								margin="normal"
								label="Other Reason"
								variant="outlined"
								value={otherRejectReason}
								onChange={(e) => setOtherRejectReason(e.target.value)}
								multiline
								rows={3}
							/>
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setOpenRejectAllDialog(false)}>
							Cancel
						</Button>
						<Button
							onClick={handleRejectAllConfirm}
							color="primary"
							disabled={
								selectedReasonId === "others" && !otherRejectReason.trim()
							}
						>
							Confirm {rejectButtonLabel}
						</Button>
					</DialogActions>
				</Dialog>

				{adminInfo && pendingBulkAction && (
					<SignDSCFlow
						adminInfo={adminInfo}
						onSuccess={handleDscSuccess}
						buttonText={getActionButtonLabel(pendingBulkAction.statusType)}
						autoOpen={showDscModal}
						onClose={handleDscModalClose}
						disabled={isDscLoading}
						isLoading={isDscLoading}
						confirmationMessage={calculationData?.confirmation_message}
					/>
				)}

				{isDscLoading && (
					<Box
						sx={{
							position: "fixed",
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							backgroundColor: "rgba(255, 255, 255, 0.7)",
							zIndex: 9999,
						}}
					>
						<CircularProgress />
					</Box>
				)}
			</Paper>
		</div>
	);
};

export default StartVerificationWorkList;
