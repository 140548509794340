import React from "react";
import { Box, Container, Grid } from "@mui/material";
import Header from "component/Header/CommonHeader";
import Footer from "component/Footer";
import OTPVerification from "pages/auth/OTPVerification";
import EnterOTPFirstTime from "./EnterOTPFirstTime";
import { useNavigate, useLocation } from "react-router-dom";

const UpdatePasswordPage = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const phoneNumber = queryParams.get("phoneNumber") || "";

	const handleResetSuccess = () => {
		navigate("/auth-update-password", {
			state: { phoneNumber },
		});
	};

	return (
		<Box className="flex flex-col min-h-screen">
			<Header />
			<Container className="flex-grow" maxWidth="sm">
				<Grid container justifyContent="center">
					<Grid item xs={12} padding={8}>
						<EnterOTPFirstTime
							phoneNumber={phoneNumber}
							onSuccess={handleResetSuccess}
						/>
					</Grid>
				</Grid>
			</Container>
			<Footer />
		</Box>
	);
};

export default UpdatePasswordPage;
