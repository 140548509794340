import React, { useState, useEffect } from "react";
import { Button, Grid, Typography, Paper, Snackbar } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const LocationAccess = ({
	onLocationChange,
	disabled = false,
	currentLocation = null,
	required = true,
}) => {
	const [locationError, setLocationError] = useState("");
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [showLocationInstructions, setShowLocationInstructions] =
		useState(false);
	const [showRetryButton, setShowRetryButton] = useState(false);
	const [hasAttemptedLocation, setHasAttemptedLocation] = useState(false);

	useEffect(() => {
		if (!disabled && !currentLocation?.latitude && !hasAttemptedLocation) {
			setHasAttemptedLocation(true);
			requestLocation();
		}
	}, [disabled, currentLocation, hasAttemptedLocation]);

	const requestLocation = () => {
		if (!navigator?.geolocation) {
			const message = "Geolocation is not supported by this browser.";
			console.error(message);
			setLocationError(message);
			setSnackbarMessage(message);
			setSnackbarOpen(true);
			setShowRetryButton(true);
			return;
		}

		if (navigator.permissions && navigator.permissions.query) {
			navigator.permissions
				.query({ name: "geolocation" })
				.then((permissionStatus) => {
					if (permissionStatus.state === "granted") {
						getCurrentPosition();
					} else if (permissionStatus.state === "prompt") {
						getCurrentPosition();
					} else {
						const message =
							"Location access is blocked. Please enable location access in your browser settings.";
						setLocationError(message);
						setSnackbarMessage(message);
						setSnackbarOpen(true);
						setShowLocationInstructions(true);
						setShowRetryButton(true);
					}
				})
				.catch(() => {
					getCurrentPosition();
				});
		} else {
			getCurrentPosition();
		}
	};

	const handleRetry = () => {
		setHasAttemptedLocation(false);
		setLocationError("");
		setSnackbarOpen(false);
		setSnackbarMessage("");
		requestLocation();
	};

	const getCurrentPosition = () => {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				try {
					const { latitude, longitude } = position.coords;
					onLocationChange(latitude, longitude);
					setSnackbarMessage("Location access granted!");
					setSnackbarOpen(true);
					setLocationError("");
					setShowRetryButton(false);
				} catch (error) {
					console.error("Error processing location data:", error);
					setLocationError("Error processing location data. Please try again.");
					setSnackbarOpen(true);
					setShowRetryButton(true);
				}
			},
			(error) => {
				console.error("Error obtaining location: ", error);
				let message = "Please allow location access for better functionality.";

				if (error.code === 1) {
					message = "Please allow location access in your browser settings.";
					setShowLocationInstructions(true);
				} else if (error.code === 2) {
					message = "Unable to get your location. Please try again.";
				} else if (error.code === 3) {
					message = "Location request timed out. Please try again.";
				}

				setLocationError(message);
				setSnackbarMessage(message);
				setSnackbarOpen(true);
				setShowRetryButton(true);
			},
			{
				enableHighAccuracy: true,
				timeout: 10000,
				maximumAge: 0,
			}
		);
	};

	const showLocationHelp = () => {
		let instructions = "";

		if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
			instructions = `
        To enable location access:
        1. Open Settings
        2. Scroll down and tap Safari
        3. Scroll down to "Settings for Websites"
        4. Tap on "Location"
        5. Select "Allow" for this website
        6. Return to this page and try again
      `;
		} else if (navigator.userAgent.match(/Android/i)) {
			instructions = `
        To enable location access:
        1. Tap the lock/info icon in the address bar
        2. Tap "Site settings"
        3. Enable "Location"
        4. Refresh the page and try again
      `;
		} else {
			instructions = `
        To enable location access:
        1. Click the lock/info icon in your browser's address bar
        2. Check your location permissions
        3. Enable location access
        4. Refresh the page and try again
      `;
		}

		setSnackbarMessage(instructions);
		setSnackbarOpen(true);
		setShowLocationInstructions(true);
	};

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
		if (!showLocationInstructions) {
			setSnackbarMessage("");
		}
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h6" className="font-bold mb-4">
					Location Access {required && <span className="text-red-500">*</span>}
				</Typography>
				{currentLocation?.latitude && currentLocation?.longitude ? (
					<Typography variant="body2" color="success.main">
						✓ Location access granted
					</Typography>
				) : (
					<>
						<Typography variant="body2" color="error" className="mb-2">
							Location access is required to proceed
						</Typography>
						{showRetryButton && (
							<Button
								variant="contained"
								color="primary"
								onClick={handleRetry}
								disabled={disabled}
								startIcon={<LocationOnIcon />}
								fullWidth
								className="mb-2"
							>
								Retry Location Access
							</Button>
						)}
						{locationError && (
							<>
								<Typography variant="body2" color="error" className="mb-2">
									{locationError}
								</Typography>
								<Button
									variant="outlined"
									color="primary"
									onClick={showLocationHelp}
									size="small"
									fullWidth
								>
									Need help? Click here
								</Button>
							</>
						)}
					</>
				)}
			</Grid>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={showLocationInstructions ? null : 6000}
				onClose={handleSnackbarClose}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Paper
					elevation={3}
					sx={{
						backgroundColor: "white",
						padding: "16px",
						maxWidth: "80vw",
						whiteSpace: "pre-line",
					}}
				>
					<Typography color="primary">{snackbarMessage}</Typography>
					{showLocationInstructions && (
						<Button
							size="small"
							onClick={() => {
								setSnackbarOpen(false);
								setShowLocationInstructions(false);
								setSnackbarMessage("");
							}}
							sx={{ marginTop: "8px" }}
						>
							Close
						</Button>
					)}
				</Paper>
			</Snackbar>
		</Grid>
	);
};

export default LocationAccess;
