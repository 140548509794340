import React, { useCallback, useEffect, useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TextField,
	Snackbar,
	Alert,
	IconButton,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
	Checkbox,
	TablePagination,
	Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import useRejectDialog from "./hooks/useRejectDialog";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";
import { Edit, Save } from "@mui/icons-material";
import WorkFilters from "component/WorkFilters";
import { getCurrentLanguage, translations } from "utils/translations";
import { useNavigate } from "react-router-dom";
import { updateWorkStatus, fetchWorkReasonList } from "pages/dashboard/api";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import BulkActions from "./helpers/BulkActions";

const ActionPlanPriority = () => {
	const [editingId, setEditingId] = useState(null);
	const [data, setData] = useState(null);
	const [orderBy, setOrderBy] = useState("");
	const [editedPriority, setEditedPriority] = useState("");
	const [imgPath, setImgPath] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: "",
		severity: "success",
	});
	const [filters, setFilters] = useState({
		category: "",
		subType1: "",
		subType2: "",
		subType3: "",
		subType4: "",
	});
	const navigate = useNavigate();
	const currentLanguage = getCurrentLanguage();
	const t = translations[currentLanguage];
	const [selected, setSelected] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const getEligibleRows = (rows) => {
		return rows.filter((row) => row.submitFlag !== "YES");
	};

	const fetchData = useCallback(async () => {
		try {
			const fetchUrl = `${DOMAIN_URL}/actionplan-priority?work_sc1_id=${filters.category}&work_sc2_id=${filters.subType1}&work_sc3_id=${filters.subType2}&work_sc3_id=${filters.subType3}&work_sc5_id=${filters.subType4}`;
			const response = await fetch(fetchUrl, {
				method: "GET",
				headers: getAuthHeaders(),
			});
			if (!response.ok) throw new Error("Network response was not ok");
			const fetchedData = await response.json();
			setData(fetchedData);
			if (fetchedData.img_path) {
				setImgPath(fetchedData.img_path);
			}
			if (fetchedData.results && fetchedData.results.length > 0) {
				setOrderBy(Object.keys(fetchedData.results[0])[0]);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			setData(null);
		} finally {
			setIsLoading(false);
		}
	}, [
		filters.category,
		filters.subType1,
		filters.subType2,
		filters.subType3,
		filters.subType4,
	]);

	const {
		openRejectDialog,
		rejectReasons,
		selectedReasonId,
		setSelectedReasonId,
		otherRejectReason,
		setOtherRejectReason,
		openDialog,
		closeDialog,
		handleReject,
	} = useRejectDialog(fetchData, setSnackbar);

	const handleFilterChange = useCallback(
		(newFilters) => {
			setFilters(newFilters);
			fetchData();
		},
		[
			filters.category,
			filters.subType1,
			filters.subType2,
			filters.subType3,
			filters.subType4,
		]
	);

	const handleEdit = (id, currentPriority) => {
		setEditingId(id);
		setEditedPriority(currentPriority);
	};

	const handleSave = async (workDemandId) => {
		try {
			const inputData = JSON.stringify({
				work_demand_id: parseInt(workDemandId),
				priority: parseInt(editedPriority),
			});
			const formData = new URLSearchParams();
			formData.append("input_data", inputData);

			const response = await fetch(`${DOMAIN_URL}/update-work-priority`, {
				method: "POST",
				headers: getAuthHeaders(),
				body: formData,
			});

			const result = await response.json();

			if (result.status) {
				setSnackbar({
					open: true,
					message: result.message,
					severity: "success",
				});
				setData((prevData) => ({
					...prevData,
					results: prevData.results.map((row) =>
						row.work_demand_id === workDemandId
							? { ...row, priority: parseInt(editedPriority) }
							: row
					),
				}));
				setEditingId(null);
			} else {
				throw new Error(result.message);
			}
		} catch (error) {
			console.error("Error updating priority:", error);
			setSnackbar({
				open: true,
				message: error.message || "Failed to update priority",
				severity: "error",
			});
		}
	};

	const handleAccept = async (workDemandId) => {
		try {
			const response = await updateWorkStatus({
				work_demand_id: workDemandId,
				status_type: "ACCEPT",
				url: "update-ap-priority-status",
			});
			if (response.status) {
				setSnackbar({
					open: true,
					message: response.message,
					severity: "success",
				});
				fetchData(); // Refresh data
			} else {
				throw new Error(response.message);
			}
		} catch (error) {
			setSnackbar({
				open: true,
				message: error.message || "Failed to accept",
				severity: "error",
			});
		}
	};

	// Function to handle selection of items
	const handleSelect = (workDemandId) => {
		const row = data.results.find((r) => r.work_demand_id === workDemandId);
		if (row.submitFlag === "YES") return; // Don't allow selection of partial submit items

		setSelected((prevSelected) =>
			prevSelected.includes(workDemandId)
				? prevSelected.filter((id) => id !== workDemandId)
				: [...prevSelected, workDemandId]
		);
	};

	const totalCount = data?.results?.length || 0;
	const selectedCount = selected.length;
	const acceptButtonLabel =
		selectedCount === totalCount
			? "Accept All"
			: `Accept Selected (${selectedCount}/${totalCount})`;
	const rejectButtonLabel =
		selectedCount === totalCount
			? "Reject All"
			: `Reject Selected (${selectedCount}/${totalCount})`;

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	if (isLoading) return <div>Loading...</div>;

	const visibleColumns =
		data && data.results && data.results[0]
			? Object.keys(data.results[0]).filter((key) => !key.includes("_id"))
			: [];

	return (
		<Paper sx={{ width: "100%", mb: 2 }}>
			<Typography
				variant="h6"
				component="div"
				className="text-2xl font-bold ml-10 xs:ml-40 sm:ml-4  xs:pt-2 pt-1 pb-2"
			>
				{t.actionplan_priority}
			</Typography>
			<div className="ml-2 mr-2 mb-2">
				<WorkFilters
					onFilterChange={handleFilterChange}
					initialFilters={filters}
				/>
			</div>
			{/* Conditionally render Bulk Actions Component */}
			{selectedCount > 1 && (
				<div className="flex justify-start ml-2 mr-2 mb-4">
					<BulkActions
						selected={selected}
						fetchData={fetchData}
						setSnackbar={setSnackbar}
						acceptButtonLabel={acceptButtonLabel}
						rejectButtonLabel={rejectButtonLabel}
					/>
				</div>
			)}
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell padding="checkbox">
								<Checkbox
									indeterminate={
										selected.length > 0 &&
										selected.length <
											getEligibleRows(data?.results || []).length
									}
									checked={
										data?.results?.length > 0 &&
										selected.length ===
											getEligibleRows(data?.results || []).length &&
										selected.length > 0
									}
									onChange={(event) => {
										if (event.target.checked) {
											const newSelecteds = data.results
												.filter((row) => row.submitFlag !== "YES")
												.map((row) => row.work_demand_id);
											setSelected(newSelecteds);
										} else {
											setSelected([]);
										}
									}}
									inputProps={{ "aria-label": "select all items" }}
								/>
							</TableCell>
							<TableCell className="bg-slate-100 text-dark-600 font-semibold align-top">
								Actions
							</TableCell>
							{visibleColumns.map((key) => (
								<TableCell
									key={key}
									className="bg-slate-100 text-dark-600 font-semibold align-top"
								>
									{key.replaceAll("_", " ").toUpperCase()}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{data?.results
							?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row) => (
								<TableRow key={row.work_demand_id}>
									<TableCell padding="checkbox">
										<Checkbox
											checked={selected.includes(row.work_demand_id)}
											onChange={() => handleSelect(row.work_demand_id)}
										/>
									</TableCell>
									<TableCell>
										{/* Action Buttons in a Flex Container */}
										<div style={{ display: "flex", alignItems: "center" }}>
											<Tooltip title="Preview">
												<IconButton
													color="primary"
													onClick={() =>
														navigate(
															`/core/premeasurement-data/demand/${row.work_demand_id}?readOnly=1`
														)
													}
												>
													<InfoIcon />
												</IconButton>
											</Tooltip>

											{row.submitFlag !== "YES" ? (
												<Tooltip title="Accept">
													<IconButton
														color="success"
														onClick={() => handleAccept(row.work_demand_id)}
													>
														<ThumbUpIcon />
													</IconButton>
												</Tooltip>
											) : row?.gp_dd_flag_id !== "1" ? (
												<Tooltip title="Partial Submit">
													<IconButton
														color="success"
														onClick={() =>
															navigate(
																`/core/premeasurement-partial-submit/${row.work_demand_id}?partialSubmit=0`
															)
														}
													>
														<RunningWithErrorsIcon />
													</IconButton>
												</Tooltip>
											) : null}
											<Tooltip title="Reject">
												<IconButton
													color="error"
													onClick={() => openDialog(row.work_demand_id)}
												>
													<ThumbDownIcon />
												</IconButton>
											</Tooltip>
										</div>
									</TableCell>
									{visibleColumns.map((key) => (
										<TableCell key={key}>
											{key === "priority" ? (
												editingId === row.work_demand_id ? (
													<div className="flex items-center">
														<TextField
															value={editedPriority}
															onChange={(e) =>
																setEditedPriority(e.target.value)
															}
															type="number"
															size="small"
															style={{ width: "80px" }} // Increased width for better visibility
														/>
														<IconButton
															onClick={() => handleSave(row.work_demand_id)}
															className="ml-2 btn-icon rounded-full bg-orange-100 hover:bg-orange-600 text-orange-600 hover:text-white"
														>
															<Save />
														</IconButton>
													</div>
												) : (
													<div className="flex items-center">
														{row[key]}
														<IconButton
															onClick={() =>
																handleEdit(row.work_demand_id, row[key])
															}
															className="ml-2 btn-icon rounded-full bg-orange-100 hover:bg-orange-600 text-orange-600 hover:text-white"
														>
															<Edit />
														</IconButton>
													</div>
												)
											) : key === "work_image" ? (
												<img
													src={`${imgPath}${row[key]}`}
													alt={row.work_title}
													style={{ width: "100px", height: "auto" }}
													loading="lazy"
												/>
											) : (
												row[key]
											)}
										</TableCell>
									))}
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 20, 50]}
				component="div"
				count={data?.results?.length || 0}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
			<Snackbar
				open={snackbar.open}
				autoHideDuration={6000}
				onClose={() => setSnackbar({ ...snackbar, open: false })}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				sx={{
					position: "fixed",
					zIndex: 9999,
					top: "200px !important", // Ensures consistent top positioning
				}}
			>
				<Alert
					severity={snackbar.severity}
					sx={{
						width: "100%",
						"& .MuiAlert-message": {
							fontSize: "1.4rem",
							fontWeight: 500,
						},
					}}
				>
					{snackbar.message}
				</Alert>
			</Snackbar>
			<Dialog open={openRejectDialog} onClose={closeDialog}>
				<DialogTitle>Reject Work</DialogTitle>
				<DialogContent>
					<FormControl fullWidth margin="normal">
						<InputLabel id="reject-reason-label">Reject Reason</InputLabel>
						<Select
							labelId="reject-reason-label"
							value={selectedReasonId}
							onChange={(e) => setSelectedReasonId(e.target.value)}
							label="Reject Reason"
						>
							{rejectReasons.map((reason) => (
								<MenuItem
									key={reason.reject_reason_id}
									value={reason.reject_reason_id}
								>
									{reason.reason_name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					{selectedReasonId === "others" && (
						<TextField
							fullWidth
							margin="normal"
							label="Other Reason"
							variant="outlined"
							value={otherRejectReason}
							onChange={(e) => setOtherRejectReason(e.target.value)}
							multiline
							rows={3}
						/>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={closeDialog}>Cancel</Button>
					<Button
						onClick={handleReject}
						color="primary"
						disabled={
							selectedReasonId === "others" && !otherRejectReason.trim()
						}
					>
						Confirm Reject
					</Button>
				</DialogActions>
			</Dialog>
		</Paper>
	);
};

export default ActionPlanPriority;
