import { Alert } from "@mui/material";
import { FC, useState } from "react";
import OtpInput from "react-otp-input";

import theme from "./theme.module.scss";
import { verifyOtp } from "../api";

const EnterOTPFirstTime: FC<{
	phoneNumber: string;
	onSuccess: (success: boolean) => void;
}> = ({ phoneNumber, onSuccess }) => {
	const [otpPhone, setOtpPhone] = useState("");
	const [validationError, setValidationError] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	function handleOtpChange(otp: string) {
		if (isLoading) return;
		setOtpPhone(otp);
		if (otp.length === 6) {
			onSubmit(otp);
		}
	}

	async function onSubmit(otp: string) {
		if (isLoading) return;
		setIsLoading(true);
		setValidationError("");

		const numbers = /^\d{6}$/;

		if (!new RegExp(numbers).test(otp)) {
			setValidationError("Please enter a valid OTP. 6 digits long.");
			setIsLoading(false);
			return;
		}

		try {
			const result = await verifyOtp(phoneNumber, otp, "true");
			if (result.status) {
				onSuccess(true);
			} else {
				setValidationError(
					result.message || "OTP verification failed. Please try again."
				);
			}
		} catch (error) {
			console.error("OTP verification error:", error);
			setValidationError("An error occurred. Please try again.");
		} finally {
			setIsLoading(false);
		}
	}

	const renderInput = (props: any) => (
		<input {...props} className={theme.otpInput} />
	);

	return (
		<div className={theme.container}>
			<p className={theme.description}>
				We've sent you a 6 digit code to the above mobile number. Please enter
				that here.
			</p>

			<div className="mt-3">
				<OtpInput
					value={otpPhone}
					onChange={handleOtpChange}
					numInputs={6}
					renderInput={renderInput}
					shouldAutoFocus
					inputStyle={
						isLoading ? { opacity: 0.6, cursor: "not-allowed" } : undefined
					}
				/>
			</div>
			{validationError && (
				<Alert severity="error" className={theme.errorAlert}>
					{validationError}
				</Alert>
			)}
		</div>
	);
};

export default EnterOTPFirstTime;
