// src/hooks/useRejectDialog.js
import { useState } from "react";
import { updateWorkStatus, fetchWorkReasonList } from "pages/dashboard/api";

const useRejectDialog = (fetchData, setSnackbar) => {
	const [openRejectDialog, setOpenRejectDialog] = useState(false);
	const [rejectReasons, setRejectReasons] = useState([]);
	const [selectedReasonId, setSelectedReasonId] = useState("");
	const [otherRejectReason, setOtherRejectReason] = useState("");
	const [currentWorkDemandId, setCurrentWorkDemandId] = useState(null);

	const openDialog = (workDemandId) => {
		setCurrentWorkDemandId(workDemandId);
		setOpenRejectDialog(true);
		fetchRejectReasons();
	};

	const closeDialog = () => {
		setOpenRejectDialog(false);
	};

	const fetchRejectReasons = async () => {
		try {
			const reasonList = await fetchWorkReasonList("actionplan-priority");
			setRejectReasons([
				...reasonList,
				{ reject_reason_id: "others", reason_name: "Others" },
			]);
		} catch (error) {
			setSnackbar({
				open: true,
				message: "Failed to fetch reject reasons",
				severity: "error",
			});
		}
	};

	const handleReject = async () => {
		if (!selectedReasonId || currentWorkDemandId === null) return;
		try {
			const response = await updateWorkStatus({
				work_demand_id: currentWorkDemandId,
				status_type: "REJECT",
				url: "update-ap-priority-status",
				reject_reason_id:
					selectedReasonId === "others" ? null : selectedReasonId,
				reason_info: selectedReasonId === "others" ? otherRejectReason : "",
			});
			if (response.status) {
				setSnackbar({
					open: true,
					message: response.message,
					severity: "success",
				});
				fetchData(); // Refresh data
			} else {
				throw new Error(response.message);
			}
		} catch (error) {
			setSnackbar({
				open: true,
				message: error.message || "Failed to reject",
				severity: "error",
			});
		} finally {
			closeDialog();
		}
	};

	return {
		openRejectDialog,
		rejectReasons,
		selectedReasonId,
		setSelectedReasonId,
		otherRejectReason,
		setOtherRejectReason,
		openDialog,
		closeDialog,
		handleReject,
	};
};

export default useRejectDialog;
