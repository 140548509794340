import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MainLogo from "assets/images/logo_nrega.png";
import SignInSignUpLink from "./SignInSignUpLink";
import LanguageSwitcher from "component/LanguageSwitcher";
import { translations, getCurrentLanguage } from "../../utils/translations";
import MenuIcon from "@mui/icons-material/Menu";

const CommonHeader = () => {
	const currentLanguage = getCurrentLanguage();
	const t = translations[currentLanguage];

	const [isOpen, setMenu] = useState(true);
	const navigate = useNavigate();
	const location = useLocation();

	const toggleMenu = () => {
		setMenu(!isOpen);
	};

	const handleNavigation = (section) => {
		navigate("/", { state: { scrollTo: section } });
	};

	return (
		<nav className="bg-white text-black sticky top-0 z-50 shadow-lg w-full">
			<div className="w-full px-4 flex items-center justify-between py-2">
				{/* Logo and title on the left */}
				<div className="flex items-center">
					<Link to="/" className="navbar-brand">
						<img src={MainLogo} className="h-16" height={65} alt="Logo" />
					</Link>
					<p className="font-bold text-2xl ml-5">Mahatma Gandhi NREGA</p>
				</div>

				{/* Navigation items and login button on the right */}
				<div className="flex items-center space-x-4">
					<LanguageSwitcher />

					{!location.pathname.includes("core") ? (
						<div className="hidden lg:flex items-center space-x-4">
							{[t.home, t.about_us, t.services, t.portfolio, t.contact_us].map(
								(item) => (
									<button
										key={item}
										onClick={() => handleNavigation(item)}
										className="nav-item text-black hover:text-gray-700"
										aria-label={`Navigate to ${item}`}
									>
										{item.charAt(0).toUpperCase() + item.slice(1)}
									</button>
								)
							)}
						</div>
					) : null}

					{/* Login/Dashboard button */}
					{location.pathname !== "/raise-demand-work" && (
						<>
							{/* Pipe separator */}
							<div className="hidden lg:block h-6 w-px bg-gray-300 mx-4"></div>
							<div className="hidden lg:block">
								<SignInSignUpLink />
							</div>
						</>
					)}

					{/* Mobile menu button */}
					<button
						type="button"
						className="lg:hidden text-black"
						onClick={toggleMenu}
						aria-label="Open navigation menu"
					>
						<span className="sr-only">Navigation Menu</span>
						<MenuIcon />
					</button>
				</div>
			</div>

			{/* Mobile menu (unchanged) */}
			<div
				className={`${
					isOpen ? "hidden" : "block"
				} lg:hidden flex flex-col w-full mt-4`}
				id="menu-collapse"
			>
				<ul className="flex flex-col space-y-2">
					{/* SignInSignUpLink moved here for mobile */}
					<li>
						<SignInSignUpLink />
					</li>
					{/* LanguageSwitcher moved here for mobile */}
					<li className="nav-item">
						<LanguageSwitcher />
					</li>
				</ul>
			</div>
		</nav>
	);
};

export default CommonHeader;
