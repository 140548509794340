import { Button, TextField } from "@mui/material";
import { FC, useState, FormEvent, useEffect } from "react";
import { NavLink } from "react-router-dom";

const EnterPhoneNumber: FC<{
	onSubmit: (phoneNumber: string, password: string) => void;
	activeTab: string;
	isLoading?: boolean;
}> = ({ onSubmit, activeTab, isLoading = false }) => {
	const [phoneNumber, setPhoneNumber] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [phoneError, setPhoneError] = useState<string>("");
	const [passwordError, setPasswordError] = useState<string>("");
	const [animationState, setAnimationState] = useState<
		"enter" | "leave" | "idle"
	>("idle");

	useEffect(() => {
		setAnimationState("leave");
		const timer = setTimeout(() => setAnimationState("enter"), 300);
		return () => clearTimeout(timer);
	}, [activeTab]);

	const validatePhoneNumber = (phone: string) => {
		const phoneRegex = /^[6-9]\d{9}$/;
		return phoneRegex.test(phone);
	};

	const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setPhoneNumber(value);
		// Clear the error when user starts typing
		if (value) setPhoneError("");
	};

	function handleSubmit(e: FormEvent) {
		e.preventDefault();
		let isValid = true;

		if (!phoneNumber.trim()) {
			setPhoneError("Phone number is required");
			isValid = false;
		} else {
			setPhoneError("");
		}

		if (!password) {
			setPasswordError("Password is required");
			isValid = false;
		} else {
			setPasswordError("");
		}

		if (isValid) {
			onSubmit(phoneNumber.trim(), password);
		}
	}

	return (
		<form
			onSubmit={handleSubmit}
			className={`
			transition-all duration-300 ease-in-out
			${animationState === "enter" ? "animate-fadeInSlide" : ""}
			${animationState === "leave" ? "animate-fadeOutSlide" : ""}
		`}
		>
			<TextField
				fullWidth
				label="Phone Number"
				variant="outlined"
				value={phoneNumber}
				onChange={handlePhoneNumberChange}
				error={!!phoneError}
				helperText={phoneError}
				className="mb-4"
			/>
			<TextField
				fullWidth
				label="Password"
				type="password"
				variant="outlined"
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				error={!!passwordError}
				helperText={passwordError}
				className="mb-4"
			/>

			<p className="mt-2 text-sm text-primary font-normal text-center w-full">
				By entering your phone number, I agree to the
				<NavLink to="terms-of-service" className="text-primary">
					{" "}
					Terms of Service{" "}
				</NavLink>{" "}
				and
				<NavLink to="terms-and-conditions" className="text-primary">
					{" "}
					Terms and conditions
				</NavLink>
				.
			</p>
			<Button
				type="submit"
				variant="contained"
				color="primary"
				className="mt-4 mx-auto block"
				disabled={isLoading}
			>
				{isLoading
					? "Loading..."
					: activeTab === "signin"
					? "Login"
					: "Register"}
			</Button>
		</form>
	);
};

export default EnterPhoneNumber;
