import {
	Table,
	TableBody,
	TableContainer,
	TableHead,
	Paper,
	TableCell,
	TableRow,
} from "@mui/material";
import { styled } from "@mui/system";

const StyledTableContainer = styled(TableContainer)({
	maxWidth: "100%",
	overflowX: "auto",
});

const StyledTableCell = styled(TableCell)({
	padding: "8px 16px",
	height: "40px",
	fontSize: "0.875rem",
	minWidth: "150px",
	whiteSpace: "nowrap",
});

const StyledTableHeadCell = styled(TableCell)({
	padding: "8px 16px",
	height: "48px",
	fontSize: "0.875rem",
	backgroundColor: "rgb(243 244 246)",
	fontWeight: "600",
	whiteSpace: "wrap",
});

const StyledTableRow = styled(TableRow)({
	"&:hover": {
		backgroundColor: "rgb(243 244 246)",
	},
});

const CalculationTable = ({ calculationList }) => {
	if (!calculationList) return null;

	// Create a filtered copy of calculationList without show_sign_dsc, approval_message, and confirmation_message
	const filteredCalculationList = Object.fromEntries(
		Object.entries(calculationList).filter(
			([key]) =>
				!["show_sign_dsc", "approval_message", "confirmation_message"].includes(
					key
				)
		)
	);

	return (
		<StyledTableContainer component={Paper} sx={{ marginTop: 2 }}>
			<Table size="small">
				<TableHead>
					<StyledTableRow>
						{Object.keys(filteredCalculationList).map((key) => (
							<StyledTableHeadCell key={key}>
								{key.replace(/_/g, " ").toUpperCase()}
							</StyledTableHeadCell>
						))}
					</StyledTableRow>
				</TableHead>
				<TableBody>
					<StyledTableRow>
						{Object.values(filteredCalculationList).map((value, index) => (
							<StyledTableCell key={index}>
								{typeof value === "number"
									? value.toLocaleString("en-IN")
									: value}
							</StyledTableCell>
						))}
					</StyledTableRow>
				</TableBody>
			</Table>
		</StyledTableContainer>
	);
};

export default CalculationTable;
