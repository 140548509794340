import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import PreMeasurementForm from "../common/PreMeasurementForm";
import { CircularProgress, Typography, Container } from "@mui/material";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";

const PremeasurementInfo = () => {
	const { work_id, work_demand_id } = useParams();
	const location = useLocation();
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	// Check if the readOnly query parameter is '1'
	const isReadOnly =
		new URLSearchParams(location.search).get("readOnly") === "1";

	useEffect(() => {
		const fetchData = async () => {
			try {
				let url;
				if (work_id) {
					url = `${DOMAIN_URL}/work-premeasurment-data/${work_id}`;
				} else if (work_demand_id) {
					url = `${DOMAIN_URL}/work-premeasurment-info/${work_demand_id}`;
				} else {
					throw new Error("No valid parameter provided");
				}

				const response = await fetch(url, {
					method: "GET",
					headers: getAuthHeaders(),
				});

				if (!response.ok) {
					throw new Error("Network response was not ok");
				}

				const data = await response.json();
				setData(data);
			} catch (err) {
				setError(err.message);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [work_id, work_demand_id]);

	if (loading) {
		return (
			<Container>
				<CircularProgress />
				<Typography>Loading...</Typography>
			</Container>
		);
	}

	if (error) {
		return (
			<Container>
				<Typography color="error">Error: {error}</Typography>
			</Container>
		);
	}

	return (
		<Container>
			{data ? (
				<PreMeasurementForm
					data={data}
					isReadOnly={isReadOnly}
					// onReject={() => {
					// 	/* handle reject */
					// }}
				/>
			) : null}
		</Container>
	);
};

export default PremeasurementInfo;
