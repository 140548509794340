import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
	Box,
	Typography,
	TextField,
	Button,
	Snackbar,
	Alert,
} from "@mui/material";

import { getAuthHeaders } from "utils/config";
import { DOMAIN_URL } from "utils/config";
import { getUserInfo } from "../pages/auth/api";

interface UpdatePasswordProps {
	message?: string;
	adminId?: string;
	roleId?: string;
	budgetYear?: string;
}

const UpdatePassword: React.FC<UpdatePasswordProps> = ({
	message,
	adminId,
	roleId,
	budgetYear,
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
		"success"
	);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);

	// Extract phoneNumber from location state
	const phoneNumber = location.state?.phoneNumber;

	const fetchUserInfo = async () => {
		try {
			const data = await getUserInfo(adminId, roleId, budgetYear);

			if (data && data.results && data.results.user_name) {
				return data.results.user_name;
			} else {
				return "";
			}
		} catch (error) {
			setSnackbarMessage("Error fetching user information");
			setSnackbarSeverity("error");
			setOpenSnackbar(true);
		}
	};

	const handleUpdatePassword = async () => {
		if (newPassword !== confirmPassword) {
			setSnackbarMessage("Passwords do not match.");
			setSnackbarSeverity("error");
			setOpenSnackbar(true);
			return;
		}

		// Disable the button to prevent multiple submissions
		setIsButtonDisabled(true);

		// Use phoneNumber from state if available, otherwise fetch user info
		const userName = phoneNumber || (await fetchUserInfo());

		if (!userName) {
			setSnackbarMessage(
				"Error fetching user information. Please try again later."
			);
			setSnackbarSeverity("error");
			setOpenSnackbar(true);
			return;
		}

		try {
			const url = `${DOMAIN_URL}/update-password`;
			const inputData = JSON.stringify({
				user_name: userName,
				new_password: newPassword,
				new_confirm_password: confirmPassword,
			});

			const formData = new URLSearchParams();
			formData.append("input_data", inputData);

			const response = await fetch(url, {
				method: "POST",
				headers: getAuthHeaders(),
				body: formData,
			});

			if (!response.ok) {
				const errorText = await response.text();
				throw new Error(
					`HTTP error! status: ${response.status}, message: ${errorText}`
				);
			}

			const data = await response.json();

			if (!data.status) {
				throw new Error(data.message || "Failed to update password");
			}

			setSnackbarMessage("Password updated successfully!");
			setSnackbarSeverity("success");
			// Keep the button disabled after success
			if (location.state?.phoneNumber) {
				setTimeout(() => {
					navigate("/auth-login");
				}, 3000);
				return;
			}
			if (message) {
				setTimeout(() => {
					window.location.reload();
				}, 3000);
			}
		} catch (error) {
			// Re-enable the button if there's an error
			setIsButtonDisabled(false);
			const errorMessage =
				(error as Error).message || "Error updating password";
			setSnackbarMessage(errorMessage);
			setSnackbarSeverity("error");
		} finally {
			setOpenSnackbar(true);
		}
	};

	return (
		<>
			<Box className="flex flex-col md:px-12 py-12 px-3">
				<Box className="my-auto">
					<Box className="mt-1 w-full max-w-sm md:max-w-full m-auto px-6 py-8 bg-white dark:bg-slate-900 rounded-md shadow-lg shadow-slate-500 dark:shadow-slate-800">
						<Typography variant="h6" className="mb-4 text-center">
							{message ? message : "Update Password"}
						</Typography>
						<TextField
							label="New Password"
							type="password"
							variant="outlined"
							fullWidth
							margin="normal"
							value={newPassword}
							onChange={(e) => setNewPassword(e.target.value)}
						/>
						<TextField
							label="Confirm New Password"
							type="password"
							variant="outlined"
							fullWidth
							margin="normal"
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}
						/>
						<Button
							variant="contained"
							color="primary"
							fullWidth
							className="mt-4"
							onClick={handleUpdatePassword}
							disabled={isButtonDisabled} // Disable button based on state
						>
							Update Password
						</Button>
					</Box>
				</Box>
			</Box>

			<Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={() => setOpenSnackbar(false)}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				sx={{ marginTop: "80px" }}
			>
				<Alert
					onClose={() => setOpenSnackbar(false)}
					severity={snackbarSeverity}
					sx={{ width: "100%" }}
				>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</>
	);
};

export default UpdatePassword;
