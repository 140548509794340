import React, { useState, useEffect } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	Typography,
	TextField,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/system";
import { uploadCommencementPhoto } from "pages/dashboard/api";
import PropTypes from "prop-types";

const Input = styled("input")({
	display: "none",
});

const CaptureAndUploadDialog = ({ open, onClose, onUploadComplete }) => {
	const [file, setFile] = useState(null);
	const [uploading, setUploading] = useState(false);
	const [previewUrl, setPreviewUrl] = useState("");
	const [remarks, setRemarks] = useState("");

	// Clean up when dialog closes
	useEffect(() => {
		if (!open) {
			setFile(null);
			setPreviewUrl("");
			setUploading(false);
			setRemarks("");
		}
	}, [open]);

	// Clean up object URL on unmount or when preview changes
	useEffect(() => {
		return () => {
			if (previewUrl) {
				URL.revokeObjectURL(previewUrl);
			}
		};
	}, [previewUrl]);

	const handleClose = () => {
		if (!uploading) {
			onClose();
		}
	};

	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0];
		setFile(selectedFile);

		// Create a preview URL for the image
		if (selectedFile && selectedFile.type.startsWith("image/")) {
			setPreviewUrl(URL.createObjectURL(selectedFile));
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!file) {
			onUploadComplete(false, "Please take a picture to upload.");
			return;
		}

		setUploading(true);
		try {
			const response = await uploadCommencementPhoto(
				file,
				remarks || "No remarks"
			);
			onUploadComplete(
				true,
				response.message || "Picture uploaded successfully!"
			);
			onClose();
		} catch (error) {
			console.log("Error uploading picture:", error);
			onUploadComplete(false, error.message || "Picture upload failed.");
		} finally {
			setUploading(false);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="photo-dialog-title"
			disableEscapeKeyDown={uploading}
			keepMounted={false}
		>
			<DialogTitle id="photo-dialog-title">
				Take a Picture and Upload
			</DialogTitle>
			<form onSubmit={handleSubmit} method="POST" encType="multipart/form-data">
				<DialogContent>
					<label htmlFor="camera-input">
						<Input
							id="camera-input"
							name="file"
							type="file"
							accept="image/*"
							capture="environment"
							onChange={handleFileChange}
							disabled={uploading}
						/>
						<Button
							component="span"
							variant="contained"
							startIcon={<CloudUploadIcon />}
							sx={{ mb: 2 }}
							disabled={uploading}
						>
							Take a Picture
						</Button>
					</label>
					{file && (
						<Typography variant="body2" sx={{ mb: 2 }}>
							Selected picture: {file.name}
						</Typography>
					)}
					{previewUrl && (
						<img
							src={previewUrl}
							alt="Preview"
							style={{
								maxWidth: "100%",
								maxHeight: "200px",
								marginBottom: "16px",
							}}
						/>
					)}
					{file && (
						<TextField
							fullWidth
							label="Add remarks (optional)"
							value={remarks}
							onChange={(e) => setRemarks(e.target.value)}
							disabled={uploading}
							margin="normal"
						/>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} disabled={uploading}>
						Cancel
					</Button>
					<Button
						type="submit"
						variant="contained"
						disabled={uploading || !file}
						startIcon={uploading ? <CircularProgress size={20} /> : null}
					>
						{uploading ? "Uploading..." : "Submit"}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

// Add prop types for better documentation and validation
CaptureAndUploadDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onUploadComplete: PropTypes.func.isRequired,
};

export default CaptureAndUploadDialog;
