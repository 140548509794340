import React, { FC, useState } from "react";
import EnterPhoneNumber from "./EnterPhoneNumber";
import { CheckUserResult } from "./types";
import { checkUserExists } from "./api";
import { Snackbar } from "@mui/material";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
interface LoginFormProps {
	onUserFound: (result: CheckUserResult) => void;
	onUserNotFound: (phoneNumber: string, password: string) => void;
	activeTab: "signin" | "signup"; // Added this prop
}

const LoginForm: FC<LoginFormProps> = ({
	onUserFound,
	onUserNotFound,
	activeTab,
}) => {
	const [phoneNumber, setPhoneNumber] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState<string | null>(null); // Add state for error message
	const [isLoading, setIsLoading] = useState(false); // Add loading state

	async function handleSubmit(phoneNumber: string, password: string) {
		if (isLoading) return; // Prevent multiple submissions
		setIsLoading(true);
		try {
			// Call the API to check if the user exists
			const result = await checkUserExists(phoneNumber, password);

			if (result.status) {
				// User found and credentials are correct
				if (result.data?.type === "OTP") {
					// OTP flag is set to true
					onUserFound(result);
				} else {
					// OTP flag is set to false
					onUserFound(result);
				}
			} else if (result.new_user) {
				// New user
				onUserNotFound(phoneNumber, password);
			} else if (!result.new_user && !result.status) {
				// Invalid password or other error
				setError("Invalid password or other error"); // Set error message
			} else {
				// This case is unexpected and should not occur based on the current logic
				console.error("Unexpected case in LoginForm:", result);
			}
		} catch (err) {
			setError("An error occurred. Please try again.");
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<>
			<div className="flex flex-col gap-4">
				<EnterPhoneNumber
					onSubmit={handleSubmit}
					activeTab={activeTab}
					isLoading={isLoading}
				/>

				{activeTab === "signin" && (
					<Typography
						variant="body2"
						color="primary"
						className="mt-2 text-center"
					>
						<Link
							to="/auth-forgot-password"
							style={{
								textDecoration: "none",
								color: "inherit",
							}}
						>
							Forgot Password?
						</Link>
					</Typography>
				)}
			</div>
			<Snackbar
				open={!!error}
				autoHideDuration={6000}
				onClose={() => setError(null)}
				message={error}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				style={{ marginTop: "80px" }}
			/>
		</>
	);
};

export default LoginForm;
