import { useEffect, useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TablePagination,
	TableSortLabel,
	Checkbox,
	Button,
	Snackbar,
	Alert,
	TextField,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";
import {
	updateAllApWorkStatus,
	fetchWorkReasonList,
} from "pages/dashboard/api";
import ActionButtons from "./ActionButtons";

const StyledTableContainer = styled(TableContainer)({
	maxWidth: "100%",
	overflowX: "auto",
});

const ActionPlanDraftList = () => {
	const [data, setData] = useState(null);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [selected, setSelected] = useState([]);
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: "",
		severity: "success",
	});
	const [search, setSearch] = useState(""); // State for search query

	const [openAcceptAllDialog, setOpenAcceptAllDialog] = useState(false);
	const [openRejectAllDialog, setOpenRejectAllDialog] = useState(false);
	const [rejectReasons, setRejectReasons] = useState([]);
	const [selectedReasonId, setSelectedReasonId] = useState("");
	const [otherRejectReason, setOtherRejectReason] = useState("");

	const getEligibleRows = (rows) => {
		return rows.filter(
			(row) =>
				!(
					row.amount_type === "VARIABLE" && Number(row.estimated_amount) < 10000
				)
		);
	};

	const fetchData = async () => {
		try {
			const fetchUrl = `${DOMAIN_URL}/actionplan-draft-list`;
			const response = await fetch(fetchUrl, {
				method: "GET",
				headers: getAuthHeaders(),
			});
			if (!response.ok) throw new Error("Network response was not ok");
			const fetchedData = await response.json();

			if (fetchedData && fetchedData.status) {
				setData(fetchedData);
				if (fetchedData.results?.length > 0) {
					setOrderBy(Object.keys(fetchedData.results[0])[0]);
				}
			} else {
				// Handle the case where status is false
				setData({
					key: fetchedData.key || "ActionPlanDraftList",
					status: false,
					results: [],
				});
				setSelected([]); // Clear selections
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			setData({
				key: "ActionPlanDraftList",
				status: false,
				results: [],
			});
			setSelected([]); // Clear selections
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleRequestSort = (property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleActionSuccess = (message) => {
		setSnackbar({
			open: true,
			message: message,
			severity: "success",
		});
		fetchData();
	};

	const handleActionError = (message) => {
		setSnackbar({
			open: true,
			message: message,
			severity: "error",
		});
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked && filteredResults?.length > 0) {
			const newSelecteds = filteredResults
				.map((n, i) => ({ index: i, row: n }))
				.filter(
					({ row }) =>
						!(
							row.amount_type === "VARIABLE" &&
							Number(row.estimated_amount) < 10000
						)
				)
				.map(({ index }) => index);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleCheckboxClick = (event, index) => {
		event.stopPropagation();
		const selectedIndex = selected.indexOf(index);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, index);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const isSelected = (index) => selected.indexOf(index) !== -1;

	const shouldShowAcceptButton = (row) => {
		return !(
			row.amount_type === "VARIABLE" && Number(row.estimated_amount) < 10000
		);
	};

	const filteredResults =
		data?.results?.filter((row) => {
			return Object.values(row).some((value) =>
				String(value).toLowerCase().includes(search.toLowerCase())
			);
		}) || []; // Default to an empty array if undefined

	const selectedCount = selected.length;
	const totalCount = filteredResults.length;

	const selectedAcceptableCount = selected.filter((index) =>
		shouldShowAcceptButton(filteredResults[index])
	).length;

	const acceptButtonLabel =
		selectedAcceptableCount === totalCount
			? "Accept All"
			: `Accept Selected (${selectedAcceptableCount}/${totalCount})`;

	const handleBulkAction = async (statusType) => {
		const workDemands = selected
			.filter(
				(index) =>
					statusType !== "ACCEPT" ||
					shouldShowAcceptButton(filteredResults[index])
			)
			.map((index) => Number(filteredResults[index].work_demand_id));

		if (statusType === "ACCEPT" && workDemands.length === 0) {
			setSnackbar({
				open: true,
				message: "No eligible rows for acceptance",
				severity: "warning",
			});
			return;
		}

		try {
			const rejectReasonId =
				selectedReasonId === "others" ? null : selectedReasonId;
			const reasonInfo = selectedReasonId === "others" ? otherRejectReason : "";

			const response = await updateAllApWorkStatus({
				work_demands: workDemands,
				status_type: statusType,
				reject_reason_id: statusType === "REJECT" ? rejectReasonId : undefined,
				reason_info: statusType === "REJECT" ? reasonInfo : "",
			});

			if (response.status) {
				setSnackbar({
					open: true,
					message: response.message || `${statusType}ed successfully`,
					severity: "success",
				});
			} else {
				setSnackbar({
					open: true,
					message: `Failed to ${statusType.toLowerCase()}: ${
						response.message || "Unknown error"
					}`,
					severity: "error",
				});
			}
			fetchData();
		} catch (error) {
			console.error(`Error during ${statusType.toLowerCase()}:`, error);
			setSnackbar({
				open: true,
				message: `Failed to ${statusType.toLowerCase()}`,
				severity: "error",
			});
		}
	};

	const handleAcceptAllClick = () => {
		setOpenAcceptAllDialog(true);
	};

	const handleRejectAllClick = () => {
		setOpenRejectAllDialog(true);
		fetchRejectReasons(); // Fetch reject reasons when opening the dialog
	};

	const handleAcceptAllConfirm = () => {
		setOpenAcceptAllDialog(false);
		handleBulkAction("ACCEPT");
	};

	const handleRejectAllConfirm = () => {
		if (!selectedReasonId) return;
		setOpenRejectAllDialog(false);
		handleBulkAction("REJECT");
	};

	const fetchRejectReasons = async () => {
		try {
			const reasonList = await fetchWorkReasonList("actionplan-draft-list");
			setRejectReasons([
				...reasonList,
				{ reject_reason_id: "others", reason_name: "Others" },
			]);
		} catch (error) {
			console.error("Error fetching reject reasons:", error);
			setSnackbar({
				open: true,
				message: "Failed to fetch reject reasons",
				severity: "error",
			});
		}
	};

	const showBulkActions = selected.length > 1; // Determine if bulk actions should be shown

	const showAcceptButtons = data?.actionFlag !== false;

	const rejectButtonLabel =
		selectedCount === totalCount
			? "Reject All"
			: `Reject Selected (${selectedCount}/${totalCount})`;

	return (
		<Paper sx={{ width: "100%", mb: 2 }}>
			<Typography
				variant="h6"
				component="div"
				className="text-2xl font-bold ml-10 xs:ml-40 sm:ml-4  xs:pt-2 pt-1"
			>
				Action Plan Draft List
			</Typography>
			<div className="flex justify-between items-center mb-2 mt-2 pl-4">
				{showBulkActions && (
					<div className="flex space-x-2">
						{showAcceptButtons && (
							<Button
								variant="contained"
								color="success"
								onClick={handleAcceptAllClick}
							>
								{acceptButtonLabel}
							</Button>
						)}
						<Button
							variant="contained"
							color="error"
							onClick={handleRejectAllClick}
						>
							{rejectButtonLabel}
						</Button>
					</div>
				)}
				<div className="ml-auto">
					{" "}
					{/* Added this div to push the TextField to the right */}
					<TextField
						variant="outlined"
						placeholder="Search…"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						className="w-full sm:w-64 mr-2"
					/>
				</div>
			</div>
			<StyledTableContainer>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							<TableCell padding="checkbox">
								<Checkbox
									indeterminate={
										selected.length > 0 &&
										selected.length < getEligibleRows(filteredResults).length
									}
									checked={
										filteredResults?.length > 0 &&
										selected.length ===
											getEligibleRows(filteredResults).length &&
										selected.length > 0
									}
									onChange={handleSelectAllClick}
									inputProps={{ "aria-label": "select all" }}
								/>
							</TableCell>

							{!showBulkActions && (
								<TableCell className="bg-slate-100 text-dark-600 font-semibold align-top">
									Actions
								</TableCell>
							)}
							{filteredResults[0] &&
								Object.keys(filteredResults[0])
									.filter((key) => !key.includes("_id"))
									.map((key) => (
										<TableCell
											key={key}
											sortDirection={orderBy === key ? order : false}
											className="bg-slate-100 text-dark-600 font-semibold align-top"
										>
											<TableSortLabel
												active={orderBy === key}
												direction={orderBy === key ? order : "asc"}
												onClick={() => handleRequestSort(key)}
											>
												{key.replaceAll("_", " ").toUpperCase()}
											</TableSortLabel>
										</TableCell>
									))}
						</TableRow>
					</TableHead>
					<TableBody>
						{filteredResults
							?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row, paginatedIndex) => {
								// Calculate the actual index in the full dataset
								const actualIndex = page * rowsPerPage + paginatedIndex;
								const isItemSelected = isSelected(actualIndex);
								return (
									<TableRow
										hover
										role="checkbox"
										aria-checked={isItemSelected}
										tabIndex={-1}
										key={`${row.work_demand_id}-${actualIndex}`}
										selected={isItemSelected}
									>
										<TableCell padding="checkbox">
											<Checkbox
												checked={isItemSelected}
												onChange={(event) =>
													handleCheckboxClick(event, actualIndex)
												}
												inputProps={{
													"aria-labelledby": `enhanced-table-checkbox-${actualIndex}`,
												}}
											/>
										</TableCell>
										{!showBulkActions && (
											<TableCell>
												<ActionButtons
													workDemandId={row.work_demand_id}
													onSuccess={handleActionSuccess}
													onError={handleActionError}
													showAcceptButton={
														showAcceptButtons &&
														!(
															row.amount_type === "VARIABLE" &&
															Number(row.estimated_amount) < 10000
														)
													}
												/>
											</TableCell>
										)}
										{Object.keys(row)
											.filter((key) => !key.includes("_id"))
											.map((key) => (
												<TableCell key={`${actualIndex}-${key}`}>
													{row[key]}
												</TableCell>
											))}
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
			</StyledTableContainer>
			<TablePagination
				rowsPerPageOptions={[20, 50, 100]}
				component="div"
				count={filteredResults?.length || 0}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
			<Snackbar
				open={snackbar.open}
				autoHideDuration={3000}
				onClose={() => setSnackbar({ ...snackbar, open: false })}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert
					onClose={() => setSnackbar({ ...snackbar, open: false })}
					severity={snackbar.severity}
				>
					{snackbar.message}
				</Alert>
			</Snackbar>

			{/* Accept All Confirmation Dialog */}
			<Dialog
				open={openAcceptAllDialog}
				onClose={() => setOpenAcceptAllDialog(false)}
			>
				<DialogTitle>Confirm {acceptButtonLabel}</DialogTitle>
				<DialogContent>
					Are you sure you want to {acceptButtonLabel.toLowerCase()}?
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenAcceptAllDialog(false)}>Cancel</Button>
					<Button onClick={handleAcceptAllConfirm} color="primary">
						Confirm
					</Button>
				</DialogActions>
			</Dialog>

			{/* Reject All Dialog */}
			<Dialog
				open={openRejectAllDialog}
				onClose={() => setOpenRejectAllDialog(false)}
			>
				<DialogTitle>{rejectButtonLabel}</DialogTitle>
				<DialogContent>
					<FormControl fullWidth margin="normal">
						<InputLabel id="reject-reason-label">Reject Reason</InputLabel>
						<Select
							labelId="reject-reason-label"
							value={selectedReasonId}
							onChange={(e) => setSelectedReasonId(e.target.value)}
							label="Reject Reason"
						>
							{rejectReasons.map((reason) => (
								<MenuItem
									key={reason.reject_reason_id}
									value={reason.reject_reason_id}
								>
									{reason.reason_name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					{selectedReasonId === "others" && (
						<TextField
							fullWidth
							margin="normal"
							label="Other Reason"
							variant="outlined"
							value={otherRejectReason}
							onChange={(e) => setOtherRejectReason(e.target.value)}
							multiline
							rows={3}
						/>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenRejectAllDialog(false)}>Cancel</Button>
					<Button
						onClick={handleRejectAllConfirm}
						color="primary"
						disabled={
							selectedReasonId === "others" && !otherRejectReason.trim()
						}
					>
						Confirm {rejectButtonLabel}
					</Button>
				</DialogActions>
			</Dialog>
		</Paper>
	);
};

export default ActionPlanDraftList;
