import React, { useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";
import Cookies from "js-cookie";

const CommencementOutdatedAlert = () => {
	const [showAlert, setShowAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");

	useEffect(() => {
		checkCommencementStatus();
	}, []);

	const checkCommencementStatus = async () => {
		try {
			const adminId = Cookies.get("admin_id");
			if (!adminId) return;

			// First fetch admin info
			const adminResponse = await fetch(`${DOMAIN_URL}/admin-info/${adminId}`, {
				method: "GET",
				headers: getAuthHeaders(),
			});

			const adminData = await adminResponse.json();

			if (!adminData.status || !adminData.results?.gp_id) return;

			// If GP ID exists, check commencement date
			const commencementResponse = await fetch(
				`${DOMAIN_URL}/commencement/${adminData.results.gp_id}`,
				{
					method: "GET",
					headers: getAuthHeaders(),
				}
			);

			const commencementData = await commencementResponse.json();

			if (
				commencementData.status &&
				commencementData.results?.commencement_date === null
			) {
				setAlertMessage(
					"Please contact your Taluk Panchayath to set the commencement date for your Grama Panchayath"
				);
				setShowAlert(true);
			} else {
				if (
					commencementData.status &&
					commencementData.results?.commencement_date
				) {
					const commencementDate = new Date(
						commencementData.results.commencement_date
					);
					const currentDate = new Date();

					if (
						commencementDate < currentDate &&
						!commencementData.results.file_name
					) {
						setAlertMessage(
							"Commencement information needs to be updated. Please upload the latest commencement details."
						);
						setShowAlert(true);
					}
				}
			}
		} catch (error) {
			console.error("Error checking commencement status:", error);
		}
	};

	const handleClose = (
		event?: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === "clickaway") {
			return;
		}
		setShowAlert(false);
	};

	return (
		<Snackbar
			open={showAlert}
			// autoHideDuration={6000}
			onClose={handleClose}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
			sx={{ marginTop: "80px" }}
		>
			<Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
				{alertMessage}
			</Alert>
		</Snackbar>
	);
};

export default CommencementOutdatedAlert;
