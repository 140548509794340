import React, { useEffect, useState } from "react";
import {
	Autocomplete,
	TextField,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	IconButton,
} from "@mui/material";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";
import { Edit, Save } from "@mui/icons-material";
import { getCurrentLanguage, translations } from "utils/translations";
import Cookies from "js-cookie";

const GpBudget = () => {
	const [tpList, setTpList] = useState([]);
	const [selectedTp, setSelectedTp] = useState(null);
	const [budgetData, setBudgetData] = useState([]);
	const [editingGpId, setEditingGpId] = useState(null); // Track the currently editing GP ID
	const [tableSearchTerm, setTableSearchTerm] = useState(""); // New state for table search term
	const [selectedGp, setSelectedGp] = useState(null);
	const [wageValue, setWageValue] = useState(0);

	const currentLanguage = getCurrentLanguage();
	const t = translations[currentLanguage];

	const fetchCurrentRole = async () => {
		const roleId = Cookies.get("role_id");
		if (!roleId) return null;
		try {
			const response = await fetch(`${DOMAIN_URL}/user-roles`, {
				method: "GET",
				headers: getAuthHeaders(),
			});

			if (!response.ok) throw new Error("Failed to fetch user roles");

			const data = await response.json();
			if (data.status && Array.isArray(data.results)) {
				return data.results.find((role) => role.role_id === roleId) || null;
			}
		} catch (error) {
			console.error("Error fetching current role:", error);
		}
		return null;
	};

	const fetchTpList = async (zp_id = "") => {
		try {
			const response = await fetch(
				`${DOMAIN_URL}/tp-list${zp_id ? "/" + zp_id : ""}`,
				{
					method: "GET",
					headers: getAuthHeaders(),
				}
			);
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			if (data.status) {
				setTpList(data.results);
			}
		} catch (error) {
			console.error("Error fetching job cards:", error);
		}
	};

	useEffect(() => {
		const initializeData = async () => {
			const role = await fetchCurrentRole();

			if (role && role.gp_id) {
				// GP user - set GP data and fetch its budget
				setSelectedGp({ gp_id: role.gp_id, gp_name: role.gp_name });
				setSelectedTp(null);
				setTpList([]);

				try {
					const response = await fetch(`${DOMAIN_URL}/gpbudget/${role.gp_id}`, {
						method: "GET",
						headers: getAuthHeaders(),
					});
					if (response.ok) {
						const data = await response.json();
						if (data.status) {
							setBudgetData(data.results);
							setWageValue(parseFloat(data.wage_value));
						}
					}
				} catch (error) {
					console.error("Failed to fetch budget data:", error);
				}
			} else if (role && role.tp_id) {
				// TP user - existing logic
				const tpData = { tp_id: role.tp_id, tp_name: role.tp_name };
				setSelectedTp(tpData);
				setTpList([]);

				try {
					const response = await fetch(
						`${DOMAIN_URL}/gp-budget/${role.tp_id}`,
						{
							method: "GET",
							headers: getAuthHeaders(),
						}
					);
					if (response.ok) {
						const data = await response.json();
						if (data.status) {
							setBudgetData(data.results);
							setWageValue(parseFloat(data.wage_value));
						}
					}
				} catch (error) {
					console.error("Failed to fetch budget data:", error);
				}
			} else if (role && role.zp_id) {
				fetchTpList(role.zp_id);
			} else {
				fetchTpList();
			}
		};

		initializeData();
	}, []);

	const handleTpSelect = async (event, value) => {
		if (!tpList.find((tp) => tp.tp_id === value?.tp_id)) {
			setSelectedTp(null);
			return;
		}

		setSelectedTp(value || null);

		if (value) {
			const response = await fetch(`${DOMAIN_URL}/gp-budget/${value.tp_id}`, {
				method: "GET",
				headers: getAuthHeaders(),
			});
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			if (data.status) {
				setBudgetData(data.results);
				setWageValue(parseFloat(data.wage_value));
			}
		} else {
			setBudgetData([]);
		}
	};

	const filteredBudgetData = budgetData.filter((gp) =>
		gp.gp_name.toLowerCase().includes(tableSearchTerm.toLowerCase())
	);

	const handleTableSearch = (event) => {
		setTableSearchTerm(event.target.value); // Update table search term
	};

	const handleBudgetEdit = (gp_id, budget) => {
		// Update the budget in the state, ensuring it's a valid number
		setBudgetData((prevData) =>
			prevData.map((gp) =>
				gp.gp_id === gp_id
					? {
							...gp,
							budget: budget,
							calculated_budget: parseFloat(budget) * parseFloat(wageValue),
					  }
					: gp
			)
		);
	};

	const handleSaveBudget = async (gp_id, budget) => {
		try {
			const url = `${DOMAIN_URL}/gp-budget-update`;
			const inputData = JSON.stringify({ gp_id, budget });

			const params = new URLSearchParams();
			params.append("input_data", inputData);

			const response = await fetch(url, {
				method: "POST",
				headers: getAuthHeaders(),
				body: params,
			});

			if (!response.ok) {
				const errorText = await response.text();
				console.error("Response error:", errorText);
				throw new Error(
					`HTTP error! status: ${response.status}, message: ${errorText}`
				);
			}

			const data = await response.json();
			if (!data.status) {
				throw new Error("Failed to update budget");
			}

			// Optionally, you can refresh the data or show a success message here
		} catch (error) {
			console.error("Error updating budget:", error);
		}
	};

	return (
		<div>
			<div className="mb-4 pt-2 ml-10 xs:ml-10">
				<Typography variant="h6">{t.gp_budget_data}</Typography>
			</div>
			<div className="flex flex-col xs:flex-row justify-between mb-4">
				{selectedGp ? (
					<Typography className="flex-grow ml-2 mt-4 xs:mr-2" variant="body1">
						<strong>{selectedGp.gp_name}</strong>
					</Typography>
				) : selectedTp ? (
					<Typography className="flex-grow ml-2 mt-4 xs:mr-2" variant="body1">
						<strong>{selectedTp.tp_name}</strong>
					</Typography>
				) : tpList.length > 0 ? (
					<Autocomplete
						className="flex-grow xs:mr-2"
						id="talukPanchayat"
						options={tpList}
						getOptionLabel={(option) => option.tp_name || ""}
						value={selectedTp}
						onChange={handleTpSelect}
						isOptionEqualToValue={(option, value) =>
							option?.tp_id === value?.tp_id
						}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Taluk Panchayat"
								fullWidth
								margin="normal"
								required
							/>
						)}
						renderOption={(props, option) => (
							<li {...props} key={`tp-${option.tp_id}`}>
								{option.tp_name}
							</li>
						)}
					/>
				) : null}
				<TextField
					className="flex-grow xs:ml-2 w-full sm:w-64 mt-4"
					variant="outlined"
					placeholder="Search Table…"
					value={tableSearchTerm}
					onChange={handleTableSearch}
				/>
			</div>

			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>GP Code</TableCell>
							<TableCell>GP Name</TableCell>
							<TableCell>Budget Year</TableCell>
							<TableCell>Man Days</TableCell>
							<TableCell>Budget</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{filteredBudgetData.map((gp) => (
							<TableRow key={gp.gp_id}>
								<TableCell>{gp.gp_code}</TableCell>
								<TableCell>{gp.gp_name}</TableCell>
								<TableCell>{gp.budget_year}</TableCell>
								<TableCell>
									<div className="flex items-center">
										{editingGpId === gp.gp_id ? (
											<>
												<TextField
													value={gp.budget || ""}
													onChange={(e) => {
														const newBudget = e.target.value;
														handleBudgetEdit(gp.gp_id, newBudget);
													}}
													type="number"
													size="small"
													style={{ width: "120px" }}
												/>
												<IconButton
													onClick={() => {
														handleSaveBudget(gp.gp_id, gp.budget);
														setEditingGpId(null);
													}}
													className="ml-2 btn-icon rounded-full bg-orange-100 hover:bg-orange-600 text-orange-600 hover:text-white"
												>
													<Save />
												</IconButton>
											</>
										) : (
											<>
												{gp.budget}
												<IconButton
													onClick={() => setEditingGpId(gp.gp_id)}
													className="ml-2 btn-icon rounded-full bg-orange-100 hover:bg-orange-600 text-orange-600 hover:text-white"
												>
													<Edit />
												</IconButton>
											</>
										)}
									</div>
								</TableCell>
								<TableCell>
									{Number(gp.budget) > 0
										? `₹${(
												parseFloat(gp.budget) * parseFloat(wageValue)
										  ).toLocaleString("en-IN", {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
										  })}`
										: "₹0.00"}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};

export default GpBudget;
