import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TablePagination,
	TableSortLabel,
	Button,
	Snackbar,
	Alert,
	TextField,
	Typography,
	Box,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Autocomplete,
} from "@mui/material";
import { styled } from "@mui/system";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";

const StyledTableContainer = styled(TableContainer)({
	maxWidth: "100%",
	overflowX: "auto",
});

const StyledTableRow = styled(TableRow)({
	"&:nth-of-type(odd)": {
		backgroundColor: "#f5f5f5",
	},
	"&:hover": {
		backgroundColor: "#eeeeee",
	},
});

const StyledTableCell = styled(TableCell)({
	padding: "8px 16px",
	whiteSpace: "nowrap",
});

const StyledTableHeadCell = styled(TableCell)({
	backgroundColor: "#1976d2",
	color: "#fff",
	padding: "8px 16px",
	whiteSpace: "nowrap",
});

const StyledTableHeadCellCalculationTable = styled(TableCell)({
	backgroundColor: "#1976d2",
	color: "#fff",
	padding: "8px 16px",
	whiteSpace: "wrap",
});

const VerificationWorkList = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [data, setData] = useState(null);
	const [pageTitle, setPageTitle] = useState("");
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: "",
		severity: "success",
	});
	const [search, setSearch] = useState("");
	const [selectedTpId, setSelectedTpId] = useState(null);

	const isZpceoUrl = location.pathname.includes("zpceo");

	const fetchData = async (tpId = null) => {
		try {
			const endpoint = location.pathname.split("/").pop();
			let fetchUrl = `${DOMAIN_URL}/${endpoint}`;

			if (tpId) {
				fetchUrl = `${DOMAIN_URL}/${endpoint}?tp_id=${tpId}`;
			}

			const response = await fetch(fetchUrl, {
				method: "GET",
				headers: getAuthHeaders(),
			});

			if (!response.ok) throw new Error("Network response was not ok");
			const fetchedData = await response.json();

			if (fetchedData && fetchedData.status) {
				setData(fetchedData);
				setPageTitle(fetchedData.key || "");

				if (fetchedData.results && fetchedData.results.length > 0) {
					setOrderBy(Object.keys(fetchedData.results[0])[0]);
				}
			}
		} catch (error) {
			setData(null);
			setPageTitle("");
		}
	};

	const getForwardedWorksPath = () => {
		const pathParts = location.pathname.split("/");
		const currentPath = pathParts[pathParts.length - 1];
		const tpId = data?.work_tp_id || null;

		// Map current path to the corresponding forwarded works path
		const pathMapping = {
			"tpad-workgp-list": "/core/tpad-forwarded-lists",
			"tpeo-workgp-list": "/core/tpeo-forwarded-lists",
			"zpdd-workgp-list": "/core/zpdd-forwarded-lists",
			"zpds-workgp-list": "/core/zpds-forwarded-lists",
			"zpceo-workgp-list": "/core/zpceo-forwarded-lists",
			// Add other mappings as needed
		};

		const basePath = pathMapping[currentPath] || "/core/forwarded-works-list";
		return `${basePath}/${tpId}`;
	};

	const getRejectedWorksPath = () => {
		const pathParts = location.pathname.split("/");
		const currentPath = pathParts[pathParts.length - 1];
		const tpId = data?.work_tp_id || null;

		// Map current path to the corresponding rejected works path
		const pathMapping = {
			"tpad-workgp-list": "/core/tpad-rejected-lists",
			"tpeo-workgp-list": "/core/tpeo-rejected-lists",
			"zpdd-workgp-list": "/core/zpdd-rejected-lists",
			"zpds-workgp-list": "/core/zpds-rejected-lists",
			"zpceo-workgp-list": "/core/zpceo-rejected-lists",
			// Add other mappings as needed
		};

		const basePath = pathMapping[currentPath] || "/core/rejected-works-list";
		return `${basePath}/${tpId}`;
	};

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const tpId = params.get("tp_id");

		// Only fetch with tp_id if it exists in URL
		fetchData(tpId);
		if (tpId) {
			setSelectedTpId(tpId);
		}
		setPage(0);
	}, [location.pathname]);

	const handleRequestSort = (property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleStartClick = (gp_id) => {
		const currentPath = location.pathname;
		navigate(`${currentPath}/${gp_id}`);
	};

	const filteredResults =
		data?.results?.filter((row) => {
			return Object.values(row).some((value) =>
				String(value).toLowerCase().includes(search.toLowerCase())
			);
		}) || [];

	const handleTpChange = (event) => {
		const newTpId = event.target.value;
		setSelectedTpId(newTpId);
		navigate(`${location.pathname}?tp_id=${newTpId}`, { replace: true });
		fetchData(newTpId);
	};

	const handleApprovedWorksClick = (gpId) => {
		const tpId = new URLSearchParams(location.search).get("tp_id");
		navigate(`/core/zpceo-approved-lists/${tpId}`);
	};

	const renderCalculationTable = () => {
		if (!data?.calculation_list) return null;

		const filteredCalculations = Object.entries(data.calculation_list).filter(
			([key]) => key !== "show_sign_dsc"
		);

		return (
			<Box className="mt-2">
				<StyledTableContainer component={Paper}>
					<Table>
						<TableHead>
							<StyledTableRow>
								{filteredCalculations.map(([key]) => (
									<StyledTableHeadCellCalculationTable key={key}>
										{key.replace(/_/g, " ").toUpperCase()}
									</StyledTableHeadCellCalculationTable>
								))}
							</StyledTableRow>
						</TableHead>
						<TableBody>
							<StyledTableRow>
								{filteredCalculations.map(([_, value], index) => (
									<StyledTableCell key={index}>{value}</StyledTableCell>
								))}
							</StyledTableRow>
						</TableBody>
					</Table>
				</StyledTableContainer>
			</Box>
		);
	};

	return (
		<Paper sx={{ width: "100%", mb: 2, pb: 2 }}>
			<div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4">
				<Typography variant="h6" component="div" className="text-2xl font-bold">
					{pageTitle}
				</Typography>
			</div>

			{renderCalculationTable()}

			<div className="flex justify-between items-center mb-2 mt-2 pl-4 flex-col sm:flex-row gap-4">
				<div className="flex flex-wrap gap-4">
					{data?.tp_list && data.tp_list.length > 0 && (
						<Autocomplete
							sx={{ width: 280 }}
							options={data.tp_list}
							getOptionLabel={(option) => option.tp_name}
							value={
								data.tp_list.find((tp) => tp.tp_id === selectedTpId) || null
							}
							onChange={(event, newValue) => {
								const newTpId = newValue ? newValue.tp_id : null;
								setSelectedTpId(newTpId);
								if (newTpId) {
									navigate(`${location.pathname}?tp_id=${newTpId}`, {
										replace: true,
									});
									fetchData(newTpId);
								}
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Select Taluk Panchayat"
									variant="outlined"
								/>
							)}
						/>
					)}

					{data?.work_tp_id > 0 ? (
						<>
							<Button
								variant="contained"
								color="success"
								className="px-4 py-2"
								onClick={() => navigate(getForwardedWorksPath())}
							>
								View Forwarded TP Works List
							</Button>
							<Button
								variant="contained"
								color="error"
								className="px-4 py-2"
								onClick={() => navigate(getRejectedWorksPath())}
							>
								View Rejected TP Works List
							</Button>
						</>
					) : null}
				</div>
				<div>
					<TextField
						variant="outlined"
						placeholder="Search…"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						className="w-full sm:w-64"
					/>
				</div>
			</div>
			{data?.results && data.results.length > 0 && (
				<>
					<Box>
						<StyledTableContainer component={Paper}>
							<Table>
								<TableHead>
									<StyledTableRow>
										<StyledTableHeadCell>ACTIONS</StyledTableHeadCell>
										{filteredResults[0] &&
											Object.keys(filteredResults[0])
												.filter((key) => !key.includes("_id"))
												.map((key) => (
													<StyledTableHeadCell
														key={key}
														sortDirection={orderBy === key ? order : false}
													>
														<TableSortLabel
															active={orderBy === key}
															direction={orderBy === key ? order : "asc"}
															onClick={() => handleRequestSort(key)}
														>
															{key.replaceAll("_", " ").toUpperCase()}
														</TableSortLabel>
													</StyledTableHeadCell>
												))}
									</StyledTableRow>
								</TableHead>
								<TableBody>
									{filteredResults
										?.slice(
											page * rowsPerPage,
											page * rowsPerPage + rowsPerPage
										)
										.map((row, index) => (
											<StyledTableRow key={index}>
												<StyledTableCell>
													<div className="flex gap-2">
														<Button
															variant="contained"
															color="primary"
															onClick={() => handleStartClick(row.gp_id)}
															className="px-3 py-1 text-sm"
														>
															Start
														</Button>
														{isZpceoUrl && (
															<Button
																variant="contained"
																color="secondary"
																onClick={() =>
																	handleApprovedWorksClick(row.gp_id)
																}
																className="px-3 py-1 text-sm"
															>
																Approved Works
															</Button>
														)}
													</div>
												</StyledTableCell>
												{Object.keys(row)
													.filter((key) => !key.includes("_id"))
													.map((key) => (
														<StyledTableCell key={`${index}-${key}`}>
															{row[key]}
														</StyledTableCell>
													))}
											</StyledTableRow>
										))}
								</TableBody>
							</Table>
						</StyledTableContainer>
					</Box>

					<TablePagination
						rowsPerPageOptions={[20, 50, 100]}
						component="div"
						count={filteredResults?.length || 0}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</>
			)}

			<Snackbar
				open={snackbar.open}
				autoHideDuration={3000}
				onClose={() => setSnackbar({ ...snackbar, open: false })}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert
					onClose={() => setSnackbar({ ...snackbar, open: false })}
					severity={snackbar.severity}
				>
					{snackbar.message}
				</Alert>
			</Snackbar>
		</Paper>
	);
};

export default VerificationWorkList;
