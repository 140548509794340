import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	Tooltip,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	DialogContentText,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TextField,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import RefreshIcon from "@mui/icons-material/Refresh";
import InfoIcon from "@mui/icons-material/Info";
import { updateWorkStatus, fetchWorkReasonList } from "pages/dashboard/api";

const ActionButtons = ({
	workDemandId,
	onSuccess,
	onError,
	showAcceptButton,
}) => {
	const location = useLocation();
	const navigate = useNavigate();
	const currentSlug = location.pathname.split("/").pop(); // Extract the last part of the URL
	const [openRejectDialog, setOpenRejectDialog] = useState(false);
	const [openReverifyDialog, setOpenReverifyDialog] = useState(false);
	const [rejectReasons, setRejectReasons] = useState([]);
	const [selectedReasonId, setSelectedReasonId] = useState("");
	const [otherRejectReason, setOtherRejectReason] = useState("");
	const [reverifyReason, setReverifyReason] = useState("");

	useEffect(() => {
		if (openRejectDialog) {
			fetchRejectReasons();
		}
	}, [openRejectDialog]);

	const fetchRejectReasons = async () => {
		try {
			const reasonList = await fetchWorkReasonList(currentSlug); // Pass the slug to the function
			setRejectReasons([
				...reasonList,
				{ reject_reason_id: "others", reason_name: "Others" },
			]);
		} catch (error) {
			console.error("Error fetching reject reasons:", error);
			onError("Failed to fetch reject reasons");
		}
	};

	const handleAction = async (actionType) => {
		try {
			const response = await updateWorkStatus({
				work_demand_id: workDemandId,
				status_type: actionType,
				url:
					actionType === "VERIFY"
						? "reverify-apwork-status"
						: "update-apwork-status",
				reject_reason_id:
					selectedReasonId === "others" ? null : selectedReasonId,
				reason_info:
					actionType === "VERIFY"
						? reverifyReason
						: selectedReasonId === "others"
						? otherRejectReason
						: "",
			});

			if (response.status) {
				onSuccess(response.message);
			} else {
				throw new Error(response.message || "Action failed");
			}
		} catch (error) {
			onError(error.message);
		}
	};

	const handleRejectClick = () => {
		setOpenRejectDialog(true);
	};

	const handleRejectConfirm = () => {
		if (!selectedReasonId) return;
		setOpenRejectDialog(false);
		handleAction("REJECT");
	};

	const handleReverifyClick = () => {
		setOpenReverifyDialog(true);
	};

	const handleReverifyConfirm = () => {
		if (!reverifyReason.trim()) return;
		setOpenReverifyDialog(false);
		handleAction("VERIFY");
	};

	return (
		<>
			<div className="flex space-x-2">
				{showAcceptButton && (
					<Tooltip title="Accept">
						<IconButton color="success" onClick={() => handleAction("ACCEPT")}>
							<ThumbUpIcon />
						</IconButton>
					</Tooltip>
				)}
				<Tooltip title="Reject">
					<IconButton color="error" onClick={handleRejectClick}>
						<ThumbDownIcon />
					</IconButton>
				</Tooltip>
				<Tooltip title="Reverify">
					<IconButton color="primary" onClick={handleReverifyClick}>
						<RefreshIcon />
					</IconButton>
				</Tooltip>
				<Tooltip title="Info">
					<IconButton
						color="primary"
						onClick={() =>
							navigate(
								`/core/premeasurement-data/demand/${workDemandId}?readOnly=1`
							)
						}
					>
						<InfoIcon />
					</IconButton>
				</Tooltip>
			</div>

			<Dialog
				open={openRejectDialog}
				onClose={() => setOpenRejectDialog(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				PaperProps={{
					style: {
						minWidth: "400px", // Set a minimum width for the dialog
					},
				}}
			>
				<DialogTitle id="alert-dialog-title">{"Reject Work"}</DialogTitle>
				<DialogContent>
					<FormControl fullWidth margin="normal">
						<InputLabel id="reject-reason-label">Reject Reason</InputLabel>
						<Select
							labelId="reject-reason-label"
							value={selectedReasonId}
							onChange={(e) => setSelectedReasonId(e.target.value)}
							label="Reject Reason"
						>
							{rejectReasons.map((reason) => (
								<MenuItem
									key={reason.reject_reason_id}
									value={reason.reject_reason_id}
								>
									{reason.reason_name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					{selectedReasonId === "others" && (
						<TextField
							fullWidth
							margin="normal"
							label="Other Reason"
							variant="outlined"
							value={otherRejectReason}
							onChange={(e) => setOtherRejectReason(e.target.value)}
							multiline
							rows={3}
						/>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenRejectDialog(false)}>Cancel</Button>
					<Button
						onClick={handleRejectConfirm}
						color="primary"
						disabled={
							selectedReasonId === "others" && !otherRejectReason.trim()
						}
					>
						Confirm Reject
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={openReverifyDialog}
				onClose={() => setOpenReverifyDialog(false)}
				aria-labelledby="reverify-dialog-title"
				aria-describedby="reverify-dialog-description"
				PaperProps={{
					style: {
						minWidth: "400px",
					},
				}}
			>
				<DialogTitle id="reverify-dialog-title">{"Reverify Work"}</DialogTitle>
				<DialogContent>
					<TextField
						fullWidth
						margin="normal"
						label="Reverify Reason"
						variant="outlined"
						value={reverifyReason}
						onChange={(e) => setReverifyReason(e.target.value)}
						multiline
						rows={3}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenReverifyDialog(false)}>Cancel</Button>
					<Button
						onClick={handleReverifyConfirm}
						color="primary"
						disabled={!reverifyReason.trim()}
					>
						Confirm Reverify
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ActionButtons;
