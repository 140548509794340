import { DOMAIN_URL, getAuthHeaders } from "utils/config";

const getApiEndpoint = (pathname: string): string => {
	if (pathname.includes("tpad-workgp-list")) {
		return `${DOMAIN_URL}/update-tpad-works`;
	} else if (pathname.includes("tpeo-workgp-list")) {
		return `${DOMAIN_URL}/update-tpeo-works`;
	} else if (pathname.includes("zpdd-workgp-list")) {
		return `${DOMAIN_URL}/update-zpdd-works`;
	} else if (pathname.includes("zpds-workgp-list")) {
		return `${DOMAIN_URL}/update-zpds-works`;
	} else if (pathname.includes("zpceo-workgp-list")) {
		return `${DOMAIN_URL}/update-zpceo-works`;
	}
	throw new Error("Invalid path for API endpoint");
};

interface UpdateWorkStatusParams {
	pathname: string;
	workDemands: number[];
	statusType: "ACCEPT" | "REJECT";
	rejectReasonId?: string;
    rejectReason?: string;
}

interface UpdateWorkStatusResponse {
	status: boolean;
	message: string;
}

export const updateWorkStatus = async (
	params: UpdateWorkStatusParams
): Promise<UpdateWorkStatusResponse> => {
	const endpoint = getApiEndpoint(params.pathname);
	const inputData = JSON.stringify({
		work_demands: params.workDemands,
		status_type: params.statusType,
		reject_reason_id: params.statusType === "REJECT" ? params.rejectReasonId : '',
		reason_info: params.statusType === "REJECT" ? params.rejectReason : "",
	});

	const formData = new URLSearchParams();
	formData.append("input_data", inputData);

	const response = await fetch(endpoint, {
		method: "POST",
		headers: getAuthHeaders(),
		body: formData,
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(
			`HTTP error! status: ${response.status}, message: ${errorText}`
		);
	}

	const data: UpdateWorkStatusResponse = await response.json();

	if (!data.status) {
		throw new Error(data.message || "Failed to update work status");
	}

	return data;
};
