import React, { useState } from "react";
import { TextField, Button, Snackbar, Alert } from "@mui/material";
import { generateOtp } from "./api";
import { useNavigate } from "react-router-dom";
import { Box, Container, Grid } from "@mui/material";
import Header from "component/Header/CommonHeader";
import Footer from "component/Footer";

const ForgotPassword = () => {
	const [phoneNumber, setPhoneNumber] = useState("");
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (isLoading) return; // Prevent multiple submissions

		try {
			setIsLoading(true);
			const otpGenerated = await generateOtp(phoneNumber, "true");
			if (otpGenerated) {
				navigate(
					`/auth-otp-verification?phoneNumber=${phoneNumber}&operation=reset`
				);
			} else {
				setSnackbarMessage("Failed to generate OTP. Please try again.");
				setOpenSnackbar(true);
			}
		} catch (error) {
			setSnackbarMessage("An error occurred. Please try again.");
			setOpenSnackbar(true);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Box className="flex flex-col min-h-screen">
			<Header />
			<Container className="flex-grow" maxWidth="sm">
				<Grid container justifyContent="center">
					<Grid item xs={12} padding={8}>
						<form onSubmit={handleSubmit}>
							<TextField
								fullWidth
								label="Phone Number"
								variant="outlined"
								value={phoneNumber}
								onChange={(e) =>
									isLoading ? null : setPhoneNumber(e.target.value)
								}
								className="mb-4"
								InputProps={{
									style: isLoading
										? { opacity: 0.6, cursor: "not-allowed" }
										: undefined,
								}}
							/>
							<div className="flex justify-center">
								<Button
									type="submit"
									variant="contained"
									color="primary"
									style={
										isLoading
											? { opacity: 0.6, cursor: "not-allowed" }
											: undefined
									}
								>
									{isLoading ? "Submitting..." : "Submit"}
								</Button>
							</div>
						</form>
						<Snackbar
							open={openSnackbar}
							autoHideDuration={6000}
							onClose={() => setOpenSnackbar(false)}
							anchorOrigin={{ vertical: "top", horizontal: "center" }}
						>
							<Alert
								onClose={() => setOpenSnackbar(false)}
								severity="info"
								sx={{ width: "100%" }}
							>
								{snackbarMessage}
							</Alert>
						</Snackbar>
					</Grid>
				</Grid>
			</Container>
			<Footer />
		</Box>
	);
};

export default ForgotPassword;
