import React from "react";
import { Link } from "react-router-dom";

import MainLogo from "../assets/images/main-logo-no-bg.png";
import TechTreeLogo from "../assets/images/Tlogo.png";

export default function Footer() {
	return (
		<>
			{/*  Start Footer  */}
			<footer className="py-8 bg-slate-600 dark:bg-gray-900">
				<div className="container">
					<div className="grid md:grid-cols-12 items-center">
						<div className="md:col-span-1">
							<Link to="#" className="logo-footer">
								<img
									src={MainLogo}
									style={{
										height: "65px",
									}}
									className="md:ms-0 mx-auto mr-2"
									alt="MGNREGA"
									title="MGNREGA"
									loading="lazy"
								/>
							</Link>
						</div>

						<div className="md:col-span-8 md:mt-0 mt-8">
							<div className="text-center">
								<p className="text-white">
									© {new Date().getFullYear()}, Developed & Managed by Rural
									Development Commissionerate, Rural Development & Panchayat Raj
									Department, Govt of Karnataka
								</p>
							</div>
						</div>

						<div className="md:col-span-1 pull-right">
							<Link to="#" className="logo-footer">
								<img
									src={TechTreeLogo}
									style={{
										height: "65px",
									}}
									height={65}
									className="md:ms-0 mx-auto"
									alt="Techtree Labs Private Limited"
									title="Techtree Labs Private Limited"
									loading="lazy"
								/>
							</Link>
						</div>

						<div className="md:col-span-2 md:mt-0 mt-8">
							<ul className="list-none foot-icon md:text-end text-center">
								<li className="inline">
									<Link
										to="https://www.youtube.com/MgnregsKarnataka"
										target="_blank"
										className="btn btn-icon btn-sm border rounded-md border-slate-700 dark:border-slate-800 hover:border-orange-600 bg-slate-800 dark:bg-gray-900 hover:bg-orange-600 dark:hover:bg-orange-600 text-gray-400 hover:text-white"
									>
										<i
											className="uil uil-youtube align-middle"
											title="youtube"
										></i>
									</Link>
								</li>

								<li className="inline ms-1">
									<Link
										to="https://www.facebook.com/MgnregsKar"
										target="_blank"
										className="btn btn-icon btn-sm border rounded-md border-slate-700 dark:border-slate-800 hover:border-orange-600 bg-slate-800 dark:bg-gray-900 hover:bg-orange-600 dark:hover:bg-orange-600 text-gray-400 hover:text-white"
									>
										<i
											className="uil uil-facebook-f align-middle"
											title="facebook"
										></i>
									</Link>
								</li>
								<li className="inline ms-1">
									<Link
										to="https://www.instagram.com/mgnregskarnataka"
										target="_blank"
										className="btn btn-icon btn-sm border rounded-md border-slate-700 dark:border-slate-800 hover:border-orange-600 bg-slate-800 dark:bg-gray-900 hover:bg-orange-600 dark:hover:bg-orange-600 text-gray-400 hover:text-white"
									>
										<i
											className="uil uil-instagram align-middle"
											title="instagram"
										></i>
									</Link>
								</li>
								<li className="inline ms-1">
									<Link
										to="https://twitter.com/MgnregsK"
										target="_blank"
										className="btn btn-icon btn-sm border rounded-md border-slate-700 dark:border-slate-800 hover:border-orange-600 bg-slate-800 dark:bg-gray-900 hover:bg-orange-600 dark:hover:bg-orange-600 text-gray-400 hover:text-white"
									>
										<i
											className="uil uil-twitter align-middle"
											title="twitter"
										></i>
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</footer>
			{/* <!-- End Footer --> */}

			{/* <!-- Back to top --> */}
			<Link
				to="home"
				id="back-to-top"
				className="back-to-top fixed hidden text-lg rounded-full z-10 bottom-5 end-5 h-9 w-9 text-center bg-orange-600 text-white leading-9"
			>
				<i className="uil uil-arrow-up"></i>
			</Link>
			{/* <!-- Back to top --> */}
		</>
	);
}
