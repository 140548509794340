import React, { useState } from "react";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TextField,
} from "@mui/material";
import {
	fetchWorkReasonList,
	updateAllApWorkStatus,
} from "pages/dashboard/api";

const BulkActions = ({
	selected,
	fetchData,
	setSnackbar,
	acceptButtonLabel,
	rejectButtonLabel,
}) => {
	const [openAcceptAllDialog, setOpenAcceptAllDialog] = useState(false);
	const [openRejectAllDialog, setOpenRejectAllDialog] = useState(false);
	const [rejectReasons, setRejectReasons] = useState([]);
	const [selectedReasonId, setSelectedReasonId] = useState("");
	const [otherRejectReason, setOtherRejectReason] = useState("");

	const handleBulkAction = async (statusType) => {
		try {
			const rejectReasonId =
				selectedReasonId === "others" ? null : selectedReasonId;
			const reasonInfo = selectedReasonId === "others" ? otherRejectReason : "";

			const response = await updateAllApWorkStatus({
				work_demands: selected,
				status_type: statusType,
				url: "update-all-ap-priority-status",
				reject_reason_id: statusType === "REJECT" ? rejectReasonId : undefined,
				reason_info: statusType === "REJECT" ? reasonInfo : "",
			});

			if (response.status) {
				setSnackbar({
					open: true,
					message: response.message || `${statusType}ed successfully`,
					severity: "success",
				});
				fetchData();
			} else {
				throw new Error(response.message || "Unknown error");
			}
		} catch (error) {
			setSnackbar({
				open: true,
				message: error.message || `Failed to ${statusType.toLowerCase()}`,
				severity: "error",
			});
		}
	};

	const fetchRejectReasons = async () => {
		try {
			const reasonList = await fetchWorkReasonList("actionplan-priority");
			setRejectReasons([
				...reasonList,
				{ reject_reason_id: "others", reason_name: "Others" },
			]);
		} catch (error) {
			setSnackbar({
				open: true,
				message: "Failed to fetch reject reasons",
				severity: "error",
			});
		}
	};

	const handleAcceptAllConfirm = () => {
		setOpenAcceptAllDialog(false);
		handleBulkAction("ACCEPT");
	};

	return (
		<div className="flex space-x-2">
			<Button
				variant="contained"
				color="success"
				onClick={() => setOpenAcceptAllDialog(true)}
			>
				{acceptButtonLabel}
			</Button>
			<Button
				variant="contained"
				color="error"
				onClick={() => {
					setOpenRejectAllDialog(true);
					fetchRejectReasons();
				}}
			>
				{rejectButtonLabel}
			</Button>

			{/* Accept All Dialog */}
			<Dialog
				open={openAcceptAllDialog}
				onClose={() => setOpenAcceptAllDialog(false)}
			>
				<DialogTitle>Confirm {acceptButtonLabel}</DialogTitle>
				<DialogContent>
					Are you sure you want to {acceptButtonLabel.toLowerCase()}?
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenAcceptAllDialog(false)}>Cancel</Button>
					<Button onClick={handleAcceptAllConfirm} color="primary">
						Confirm
					</Button>
				</DialogActions>
			</Dialog>

			{/* Reject All Dialog */}
			<Dialog
				open={openRejectAllDialog}
				onClose={() => setOpenRejectAllDialog(false)}
			>
				<DialogTitle>Confirm {rejectButtonLabel}</DialogTitle>
				<DialogContent>
					<FormControl fullWidth margin="normal">
						<InputLabel id="reject-reason-label">Reject Reason</InputLabel>
						<Select
							labelId="reject-reason-label"
							value={selectedReasonId}
							onChange={(e) => setSelectedReasonId(e.target.value)}
							label="Reject Reason"
						>
							{rejectReasons.map((reason) => (
								<MenuItem
									key={reason.reject_reason_id}
									value={reason.reject_reason_id}
								>
									{reason.reason_name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					{selectedReasonId === "others" && (
						<TextField
							fullWidth
							margin="normal"
							label="Other Reason"
							variant="outlined"
							value={otherRejectReason}
							onChange={(e) => setOtherRejectReason(e.target.value)}
							multiline
							rows={3}
						/>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenRejectAllDialog(false)}>Cancel</Button>
					<Button
						onClick={() => {
							setOpenRejectAllDialog(false);
							handleBulkAction("REJECT");
						}}
						color="primary"
						disabled={
							selectedReasonId === "others" && !otherRejectReason.trim()
						}
					>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default BulkActions;
