import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TablePagination,
	Typography,
	CircularProgress,
	Box,
} from "@mui/material";
import { styled } from "@mui/system";
import { DOMAIN_URL, getAuthHeaders } from "utils/config";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const StyledTableContainer = styled(TableContainer)({
	maxWidth: "100%",
	overflowX: "auto",
});

const StyledTableCell = styled(TableCell)({
	padding: "8px 16px",
	height: "40px",
	fontSize: "0.875rem",
	minWidth: "150px",
	whiteSpace: "nowrap",
});

const StyledTableHeadCell = styled(TableCell)({
	padding: "8px 16px",
	height: "48px",
	fontSize: "0.875rem",
	backgroundColor: "rgb(243 244 246)",
	fontWeight: "600",
	minWidth: "150px",
	whiteSpace: "nowrap",
});

const LoadingContainer = styled(Box)({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	minHeight: "400px",
});

const ForwardedWorksList = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [data, setData] = useState(null);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [isLoading, setIsLoading] = useState(true);
	const [title, setTitle] = useState("");

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				const pathParts = location.pathname.split("/");
				const currentPath = pathParts[pathParts.length - 2];
				const gpId = pathParts[pathParts.length - 1];

				const response = await fetch(`${DOMAIN_URL}/${currentPath}/${gpId}`, {
					method: "GET",
					headers: {
						...getAuthHeaders(),
						"Content-Type": "application/json",
					},
				});

				if (!response.ok) throw new Error("Network response was not ok");
				const fetchedData = await response.json();

				setTitle(fetchedData.key || "");

				if (fetchedData?.status !== false) {
					setData(fetchedData);
				} else {
					setData(null);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
				setData(null);
			} finally {
				setIsLoading(false);
			}
		};

		fetchData();
	}, [location]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleBack = () => {
		navigate(-1);
	};

	if (isLoading) {
		return (
			<LoadingContainer>
				<CircularProgress />
			</LoadingContainer>
		);
	}

	return (
		<Box sx={{ width: "100%" }}>
			<div className="flex items-center gap-4 mb-6">
				<button
					onClick={handleBack}
					className="flex items-center justify-center p-2 rounded-full hover:bg-gray-100"
				>
					<ArrowBackIcon />
				</button>
				{title && (
					<Typography variant="h6" component="h2" sx={{ fontWeight: "bold" }}>
						{title}
					</Typography>
				)}
			</div>

			{!data?.results?.length ? (
				<Typography>No data available</Typography>
			) : (
				<Paper sx={{ width: "100%", mb: 2 }}>
					<StyledTableContainer>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									{Object.keys(data.results[0])
										.filter((key) => !key.includes("_id"))
										.map((header) => (
											<StyledTableHeadCell key={header}>
												{header.replace(/_/g, " ").toUpperCase()}
											</StyledTableHeadCell>
										))}
								</TableRow>
							</TableHead>
							<TableBody>
								{data.results
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => (
										<TableRow key={index}>
											{Object.entries(row)
												.filter(([key]) => !key.includes("_id"))
												.map(([key, value]) => (
													<StyledTableCell key={key}>{value}</StyledTableCell>
												))}
										</TableRow>
									))}
							</TableBody>
						</Table>
					</StyledTableContainer>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={data.results.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Paper>
			)}
		</Box>
	);
};

export default ForwardedWorksList;
