import React from "react";
import { TextField, Button, Grid, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";

const PhotoUpload = ({
	onPhotoChange,
	error,
	disabled,
	helperText,
	label = "Upload Photo",
	required = true,
}) => {
	if (isMobile) {
		return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="body2" className="text-gray-600 mb-2">
						Take a photo:
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextField
						type="file"
						accept="image/*"
						onChange={onPhotoChange}
						fullWidth
						error={!!error}
						helperText={error}
						inputProps={{
							capture: "environment",
							style: { display: "none" },
						}}
						disabled={disabled}
						sx={{
							"& .MuiOutlinedInput-root": {
								padding: 0,
								border: "none",
								"& fieldset": { border: "none" },
							},
						}}
						InputProps={{
							startAdornment: (
								<Button
									component="label"
									variant="contained"
									fullWidth
									disabled={disabled}
									className="bg-red-500 hover:bg-red-600 text-white w-full normal-case rounded-md py-2"
								>
									Use Camera
									<input
										type="file"
										accept="image/*"
										capture="environment"
										hidden
										onChange={onPhotoChange}
									/>
								</Button>
							),
						}}
					/>
				</Grid>
			</Grid>
		);
	} else {
		return (
			<TextField
				type="file"
				accept="image/*"
				onChange={onPhotoChange}
				error={!!error}
				helperText={helperText}
				inputProps={{
					style: { display: "none" },
				}}
				disabled={disabled}
				sx={{
					"& .MuiOutlinedInput-root": {
						padding: 0,
						border: "none",
						"& fieldset": { border: "none" },
					},
				}}
				InputProps={{
					startAdornment: (
						<Button
							component="label"
							variant="contained"
							disabled={disabled}
							className="bg-red-500 hover:bg-red-600 text-white normal-case rounded-md py-2"
						>
							Choose Photo
							<input
								type="file"
								accept="image/*"
								hidden
								onChange={onPhotoChange}
							/>
						</Button>
					),
				}}
			/>
		);
	}
};

export default PhotoUpload;
